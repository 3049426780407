import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect, useContext } from "react";
import { AppContext } from "../App.js";
import { Lobby } from "./Lobby.js";
import Cookies from "universal-cookie";
import {
  getDatabase,
  ref,
  set,
  push,
  hasChild,
  exists,
  get,
  serverTimestamp,
} from "firebase/database";
import { getAuth, onAuthStateChanged, GoogleAuthProvider } from "firebase/auth";
import { db, auth, uid } from "../firebase-config.js";
import { signInAnonymously, updateProfile } from "firebase/auth";
import "../styles/Auth.css";
import logo from '../styles/images/kickthefool.svg';
import avatarBackground from '../styles/images/avatarBackground.svg';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { avataaars, lorelei, bigSmile } from "@dicebear/collection";
import { getRandomBoolean } from "../utilities/helperFunctions.js";

export const Auth = ({ shouldJoin, setShouldJoin }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [playerNameInput, setPlayerNameInput] = useState("");
  const [debouncedName, setDebouncedName] = useState(playerNameInput);

  // erstelle avatare
  // https://www.dicebear.com/styles/avataaars/

  /*
  const avatar = useMemo(() => {
    console.log(playerNameInput)


    return(
      createAvatar(bigSmile, {
        size: 70,
        seed: playerNameInput,
        backgroundColor: []
      }).toDataUri()
    )
  }, [playerNameInput]);
  */

  // Debouncing: Avatar aktualisiert sich erst nach 200ms, nachdem der Benutzer aufgehört hat zu tippen
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedName(playerNameInput);
    }, 200);

    return () => {
      clearTimeout(handler); // Räumt den Timeout auf, wenn der Benutzer weiter tippt
    };
  }, [playerNameInput]);

  // Erstelle Avatar auf Basis des debounced Wertes
  const avatar = useMemo(() => {
    return createAvatar(bigSmile, {
      size: 70,
      seed: debouncedName,
      backgroundColor: [],
    }).toDataUri();
  }, [debouncedName]);

  let localRoomID;
  const roomInputRef = useRef(null);
  let navigate = useNavigate();

  const schema = yup.object().shape({
    name: yup.string().required("Please write a Nickname"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const signIn = async (data) => {
    setIsButtonDisabled(true); // Deaktiviert den Button nach dem ersten Klick
    try {
      const result = await signInAnonymously(auth);
      await updateProfile(auth.currentUser, {
        displayName: data.name,
      });
      setIsAuth(true);
      createRoom();
    } catch (err) {
      console.error(err);
      setIsButtonDisabled(false); // Falls ein Fehler auftritt, den Button wieder aktivieren
    }
  };

  async function createRoom() {
    try {
      const newRoomRef = push(ref(db, "rooms/"));
      const newRoomData = {
        lastActivity: serverTimestamp(),
        active: true,
        players: {
          [uid]: {
            isCreator: true,
            playerName: auth.currentUser.displayName,
            lives: 3,
            online: true,
          },
        },
        questions: {
          question: "",
          correctAnswer: "",
          playerAnswer: "",
        },
        status: {
          hasStarted: false,
          playerNumber: 1,
        },
        settings: {
          lives: 2,
          rounds: 2,
          questionTime: 30,
          votingTime: 45,
        },
      };
      await set(newRoomRef, newRoomData);
      setShouldJoin(false);
      navigate(`/room/${newRoomRef.key}`);

      return newRoomRef.key;
    } catch (error) {
      console.error(error);
      setIsButtonDisabled(false); // Falls ein Fehler auftritt, den Button wieder aktivieren
      throw new Error("Failed to create room");
      setErrorMessage(error.message);
    }
  }
  return (
	
    <div className="auth">
                  <img className="auth-headline" src={logo} alt="Logo" />

  
      <h5 className="inspiration-note">
        Inspired by  
        <a
           className="youtuber-link"
          href="https://www.youtube.com/@Reved"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Reved
        </a>{" "}
        and
        <a
         className="youtuber-link"
          href="https://www.youtube.com/@pietsmiet"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Pietsmiet
        </a>
      </h5>
      <div className="auth-avatar-div">
      <img className="auth-avatar-background" src={avatarBackground} />

      <img className="auth-avatar" src={avatar} alt="Avatar" />
    </div>
    <form className="auth-form" onSubmit={handleSubmit(signIn)}>

          <input
                       className="auth-input"
                       maxLength="14"
                       placeholder="type name..."
                       {...register("name")}
                       disabled={isButtonDisabled} // Deaktiviert das Eingabefeld während der Ausführung
                       onChange={(e) => {
                         setPlayerNameInput(e.target.value);
                       }}
          />
        <p className="auth-error">{errors.name?.message || errorMessage}</p>
        <button
          className="create-room-button"
          type="submit"
          disabled={isButtonDisabled} 
        >
          Create Room
        </button>
        </form>

      <div className="info-links">
        <a href="/about" className="info-link">
          About
        </a>
        <a href="/contact" className="info-link">
          Contact
        </a>
        <a href="/terms-and-conditions" className="info-link">
          Terms and Conditions
        </a>
        <a href="/credits" className="info-link">
          Credits
        </a>
      </div>
    </div>

  );
};
