import React, { useState, useEffect } from 'react';

export const RandomQuestion = ({ question, className, writingSpeed }) => {
  const [displayText, setDisplayText] = useState('');
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      setDisplayText(question.slice(0, currentIndex + 1));
      currentIndex++;

      if (currentIndex >= question.length) {
        clearInterval(interval);
      }
    }, writingSpeed); // Geschwindigkeit des Typewriter-Effekts anpassen (in Millisekunden)

    return () => clearInterval(interval);
  }, [question]);



  return (
    <h1 className={className}>
      {displayText}
   
    </h1>
  );
};
