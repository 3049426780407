import { set, remove, ref, child, update } from 'firebase/database';
import germanQuestionData from "../germanQuestions.json"
import englishQuestionData from "../englishQuestions.json"
import germanEstimationQuestions from "../germanEstimationQuestions.json"
import englishEstimationQuestions from "../englishEstimationQuestions.json"



export const initializePlayerLives = async (roomID, newLives, players, db) => {
  // Erstelle ein Array von Promises, das alle set-Operationen enthält
  const promises = players.map(player => {
    return set(ref(db, 'rooms/' + roomID + '/players/' + player.playerID + '/lives'), newLives);
  });

  // Warte, bis alle set-Operationen abgeschlossen sind
  await Promise.all(promises);
};

// Funktion, um eine zufällige Frage und die dazugehörige Antwort auszuwählen
// Funktion, um alle Fragen aus den Kategorien zusammenzuführen
const getAllQuestions = (data) => {
  return data.categories.flatMap(category => category.questions);
};

// Funktion, um eine zufällige Frage und die dazugehörige Antwort auszuwählen
export const getRandomQuestion = (currQuestion, language) => {
  const data = language === 'german' ? germanQuestionData : englishQuestionData;
  const allQuestions = getAllQuestions(data);

  let randomQuestion = currQuestion;

  // Wähle eine zufällige Frage, die nicht der aktuellen Frage entspricht
  do {
    const randomIndex = Math.floor(Math.random() * allQuestions.length);
    randomQuestion = allQuestions[randomIndex];
  } while (randomQuestion.question === currQuestion);

  return randomQuestion;
};


// Funktion, um eine zufällige Frage und die dazugehörige Antwort auszuwählen
export const getRandomEstimationQuestion = (language) => {
  const questions = language === 'german' ? germanEstimationQuestions.questions : englishEstimationQuestions.questions;
  const randomIndex = Math.floor(Math.random() * questions.length);

  return questions[randomIndex];
};



/*
let timerId; // Variable, um die ID des Intervals zu speichern
let startTime; // Variable, um den Startzeitpunkt des Timers zu speichern
export const timer = (time, setCount, startAt, serverTimeOffset) => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const timeLeft = (time * 1000) - (Date.now() - startAt - serverTimeOffset);
      if (timeLeft <= 0) {
        clearInterval(interval);
        setCount(0.0);
        resolve(); // Resolve the Promise when the timer completes
      } else {
        //setCount(parseFloat(`${Math.floor(timeLeft/1000)}.${timeLeft % 1000}`));
        setCount(Math.floor(timeLeft / 1000)); // Zeile geändert
      }
    }, 100);
  });
};
*/

/*
export const timer = async (time, setCount, startAt, serverTimeOffset) => {
  const interval = setInterval(() => {
    const timeLeft = (time * 1000) - (Date.now() - startAt - serverTimeOffset);
    if (timeLeft < 0) {
      clearInterval(interval);
     setCount(0.0);
     return;
    }
    else {
      //console.log(parseFloat(`${Math.floor(timeLeft/1000)}.${timeLeft % 1000}`));    
      setCount(parseFloat(`${Math.floor(timeLeft/1000)}.${timeLeft % 1000}`));
  }
  }, 100)
}




export const timer = async (time, setCount, startAt, serverTimeOffset) => {
  console.log("timer aufgerufen");
  await timer1(time, setCount, startAt, serverTimeOffset);
}*/




