
import { signInAnonymously,signOut, updateProfile } from "firebase/auth";
import '../styles/Auth.css';
import logo from '../styles/images/kickthefool.svg';
import avatarBackground from '../styles/images/avatarBackground.svg';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from "react-router-dom";
import {Auth} from "./Auth";
import {Lobby} from "./Lobby";
import Cookies from 'universal-cookie';
import { getDatabase, ref, set, push, hasChild, exists,get, update  } from "firebase/database";
import {getAuth,onAuthStateChanged, GoogleAuthProvider} from "firebase/auth"
import {db, auth, uid,} from '../firebase-config.js';
import { useState, useRef, useEffect, useContext } from "react";
import {AppContext} from "../App"
import { useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { avataaars, lorelei, bigSmile } from "@dicebear/collection";
export const AuthJoin = (props) => {

  const maxPlayerNumber = 12;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Neuer State für die Button-Aktivierung
  const [errorMessage, setErrorMessage] = useState(""); // Nur ein State für alle Fehler

  const [playerNameInput, setPlayerNameInput] = useState("");
  const [debouncedName, setDebouncedName] = useState(playerNameInput);

  
  // erstelle avatare
  // https://www.dicebear.com/styles/avataaars/

/*
  const avatar = useMemo(() => {
    console.log(playerNameInput)


    return(
      createAvatar(bigSmile, {
        size: 70,
        seed: playerNameInput,
        backgroundColor: []
      }).toDataUri()
    )
  }, [playerNameInput]);
  */

 // Debouncing: Avatar aktualisiert sich erst nach 200ms, nachdem der Benutzer aufgehört hat zu tippen
 useEffect(() => {
  const handler = setTimeout(() => {
    setDebouncedName(playerNameInput);
  }, 200);

  return () => {
    clearTimeout(handler); // Räumt den Timeout auf, wenn der Benutzer weiter tippt
  };
}, [playerNameInput]);

// Erstelle Avatar auf Basis des debounced Wertes
const avatar = useMemo(() => {
  return createAvatar(bigSmile, {
    size: 70,
    seed: debouncedName,
    backgroundColor: []
  }).toDataUri();
}, [debouncedName]);

  const schema = yup.object().shape({
    name: yup.string().required("Please write a Nickname"),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  async function joinRoom() {
    try {
      // Überprüfe, ob der Raum existiert
      const roomRef = ref(db, `rooms/${props.roomID}`);
      const roomSnapshot = await get(roomRef);
      if (roomSnapshot.val() === null) {
        throw new Error("Room does not exist");
      }

      // Überprüfe, ob der Benutzer bereits im Raum ist
      const playersRef = ref(db, `rooms/${props.roomID}/players`);
      const playersSnapshot = await get(playersRef);
      if (playersSnapshot.hasChild(uid)) {
        throw new Error("Already in room");
      }

      // Überprüfe, ob die maximale Anzahl von Spielern erreicht ist
      const roomData = roomSnapshot.val();
      if (roomData.status.playerNumber >= maxPlayerNumber) {
        throw new Error("Max player limit reached");
      }

      // Überprüfe, ob das Spiel bereits gestartet wurde
      if (roomData.status.hasStarted) {
        throw new Error("Game already started");
      } 

  
    

      // Füge den Benutzer dem Raum hinzu
        try {
          await set(ref(db, `rooms/${props.roomID}/players/${uid}`), {
            isCreator: false,
            playerName: auth.currentUser.displayName,
            lives: 3,
            online: true,
          });
        } catch (error) {
          //console.error("Fehler beim Speichern der Daten:", error);
          // Hier kannst du zusätzliche Fehlerbehandlung einfügen, z.B. eine Fehlermeldung anzeigen oder alternative Aktionen durchführen
        }


      // Aktualisiere die playerNumber im Raum
      const newPlayerNumber = roomData.status.playerNumber + 1;
      await update(ref(db, `rooms/${props.roomID}/status`), {
        playerNumber: newPlayerNumber,
      });

      // Benachrichtige die UI, dass der Prozess abgeschlossen ist
      props.setShouldJoin(false);
    } catch (error) {
      setErrorMessage(error.message);
      setIsButtonDisabled(false); // Button wieder aktivieren, wenn ein Fehler auftritt
    }
  }

  const signIn = async (data) => {
    setIsButtonDisabled(true); // Deaktiviert den Button nach dem ersten Klick
    try {
      const result = await signInAnonymously(auth);
      await updateProfile(auth.currentUser, {
        displayName: data.name,
      });

      props.setIsAuth(true);
      joinRoom();
    } catch (err) {
      console.error(err);
      setIsButtonDisabled(false); // Button wieder aktivieren, wenn ein Fehler auftritt
    }
  }

  return (
	
    <div className="auth">
                  <img className="auth-headline" src={logo} alt="Logo" />

  
      <h5 className="inspiration-note">
        Inspired by 
        <a
           className="youtuber-link"
          href="https://www.youtube.com/@Reved"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Reved
        </a>{" "}
        and
        <a
         className="youtuber-link"
          href="https://www.youtube.com/@pietsmiet"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Pietsmiet
        </a>
      </h5>
      <div className="auth-avatar-div">
      <img className="auth-avatar-background" src={avatarBackground} />

      <img className="auth-avatar" src={avatar} alt="Avatar" />
    </div>
    <form className="auth-form" onSubmit={handleSubmit(signIn)}>

          <input
                       className="auth-input"
                       maxLength="14"
                       placeholder="type name..."
                       {...register("name")}
                       disabled={isButtonDisabled} // Deaktiviert das Eingabefeld während der Ausführung
                       onChange={(e) => {
                         setPlayerNameInput(e.target.value);
                       }}
          />
        <p className="auth-error">{errors.name?.message || errorMessage}</p>
        <button
          className="create-room-button"
          type="submit"
          disabled={isButtonDisabled} 
        >
          Join
        </button>
        </form>

      <div className="info-links">
        <a href="/about" className="info-link">
          About
        </a>
        <a href="/contact" className="info-link">
          Contact
        </a>
        <a href="/terms-and-conditions" className="info-link">
          Terms and Conditions
        </a>
        <a href="/credits" className="info-link">
          Credits
        </a>
      </div>
    </div>

  );
};
