// Zufälligen Boolean-Wert generieren
import FavoriteIcon from '@mui/icons-material/Favorite';
import { GiBrain } from 'react-icons/gi'; // Importiere das Gehirn-Icon von FontAwesome
import brain from "../styles/images/brain.svg";

import {
  onValue,
  getDatabase,
  onChildChanged,
  ref,
  set,
  update,
  push,
  hasChild,
  exists,
  get,
  serverTimestamp,
  off,
  remove,
} from "firebase/database";
import { db, auth, uid } from "../firebase-config.js";


export const getRandomBoolean = () => {
    return Math.random() < 0.5;
  }
  
  export   const renderBrains = (numLives) => {
    return (
      <div className="game-lives">
        {Array.from({ length: numLives }, (_, i) => (
          <img
            key={i}
            className="game-live"
            src={brain}
            alt={`Brain ${i + 1}`}
          />
        ))}
      </div>
    );
  };

  export function waitForever() {
    return new Promise(() => {}); // Ein Promise, das niemals aufgelöst oder abgelehnt wird
  }
  export const wait = (milliseconds) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds))

  export const getPlayerIndexById = (playerID, playersArray) => {
    for (let i = 0; i < playersArray.length; i++) {
      


      if (playersArray[i].playerID === playerID) {
        return i;
      }
    }
    return -1; // Return -1 if the playerID is not found
  }

  export const getPlayerById = (playerID, playersArray) => {
    for (let i = 0; i < playersArray.length; i++) {
      


      if (playersArray[i].playerID === playerID) {
        return playersArray[i];
      }
    }
    return -1; // Return -1 if the playerID is not found
  }


export const getTopVotedPlayers = (players) => {
  if (players.length === 0) return [];

  // Finde die maximale Anzahl an Stimmen
  let maxVotes = Math.max(...players.map(player => player.votedBy.length));
  // Finde alle Spieler mit der maximalen Anzahl an Stimmen
  return players.filter(player => player.votedBy.length === maxVotes);
}

export const resetAllVotes = (players, roomID) => {
  for (const player of players) {
    remove(ref(db, `rooms/${roomID}/players/${player.playerID}/votedBy`));
  }
}

export const getAlivePlayers = (players, roomID) => {
  if (players.length === 0) return [];
  return players.filter(player => player.lives > 0);
}


export const getNextAlivePlayerIndex = (players, playerCounter) => {
  let nextAlivePlayer = playerCounter + 1;
  while(nextAlivePlayer < players.length && players[nextAlivePlayer].lives <= 0){
    nextAlivePlayer++;
  }
  return nextAlivePlayer
}




export const resetRoom = async (roomID, lives, rounds, questionTime, votingTime) => {
  // nutze promises um alle Befehle parallel zu senden und erst zu beenden wenn alle angekommen.
  // Erstelle ein Array von Promises für alle Firebase-Operationen
  const promises = [];

  promises.push(remove(ref(db, `rooms/${roomID}/history`)));
  promises.push(remove(ref(db, `rooms/${roomID}/status`)));


  promises.push(set(ref(db, `rooms/${roomID}/status/hasStarted`), false));
  promises.push(set(ref(db, `rooms/${roomID}/questions`), {
    question: "",
    correctAnswer: "",
    playerAnswer: "",
  }));
  promises.push(set(ref(db, `rooms/${roomID}/settings`), {
    lives: lives,
    rounds: rounds,
    questionTime: questionTime,
    votingTime: votingTime,
  }));


  // Warte, bis alle Promises erfüllt sind
  await Promise.all(promises);
};

export const totalVoteNumber =  (players) => {
  return players.reduce((totalVotes, player) => {
      return totalVotes + player.votedBy.length;
  }, 0);
}

export const creatorExists = (players) => {
  return players.some(player => player.isCreator === true);
};


export const getFirstDifferentPlayerIndex = (uid, playersArray) => {
  for (let i = 0; i < playersArray.length; i++) {
    if (playersArray[i].playerID !== uid) {
      return i;
    }
  }
  return 0; // Falls kein anderer Spieler gefunden wird
};



export const removeAllOfflinePlayers = (playersArray, roomID) => {
  playersArray.forEach(player => {
    if (!player.online) {
      // Spieler aus der Firebase Realtime Database entfernen
      remove(ref(db, "rooms/" + roomID + "/players/" + player.playerID));
    }
  });
};