import {
  initializePlayerLives,
  getRandomQuestion,
  timer,
} from "../utilities/gameFunctions.js";
import { useState, useRef, useEffect, useContext } from "react";
import {
  getPlayerIndexById,
  renderBrains,
  getPlayerById,
  creatorExists,
  getNextAlivePlayerIndex,
  getAlivePlayers,
  waitForever,
  wait,
  avatars,
} from "../utilities/helperFunctions.js";
import "../styles/Game.css";
import { db, auth, uid } from "../firebase-config.js";
import { VolumeSlider } from "./sections/VolumeSlider.js";
import { TimerAnimation } from "./sections/TimerAnimation.js";
import avatarBackground2 from "../styles/images/avatarBackground2.svg";
import creatorCrown from "../styles/images/creatorCrown.svg";
import brain from "../styles/images/brain.svg";
import playerArrow from "../styles/images/playerArrow.svg";
import {
  onValue,
  getDatabase,
  onChildChanged,
  ref,
  set,
  push,
  hasChild,
  exists,
  get,
  serverTimestamp,
  remove,
  onDisconnect,
  update,
} from "firebase/database";
import { createAvatar } from "@dicebear/core";
import { avataaars, lorelei, dylan, bigSmile } from "@dicebear/collection";
import { RandomQuestion } from "./sections/RandomQuestion.js";
import { LastAnswer } from "./sections/LastAnswer.js";
import { motion } from "framer-motion";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import FavoriteIcon from "@mui/icons-material/Favorite";

import { useMemo } from "react";
//import { update } from "lodash";

export const Game = ({
  setWinner,
  language,
  volume,
  setVolume,
  isCreator,
  setIsCreator,
  lives,
  rounds,
  questionTime,
  votingTime,
  playerNumber,
  players,
  db,
  roomID,
  setPlayers,
  setShowVoting,
  votingNumber,
  setVotingNumber,
  off,
  setShowWinnerScreen,

  setShouldJoin,
  setInLobby,
  setShowFinalScreen,
  setFinalists,

  listener1Ref,
  listener2Ref,
  listener3Ref,
  listener4Ref,
  listener5Ref,
  clockSound,
  typingSound,
}) => {
  const [isFirstGameInit, setIsFirstGameInit] = useState(true);
  const dummyCounterRef = useRef(0);

  const [startGame, setStartGame] = useState(0);

  const [count, setCount] = useState(questionTime);
  const [round, setRound] = useState(1);
  const [answerInDB, setAnswerInDB] = useState(0);
  const [playerIsPlaying, setPlayerIsPlaying] = useState(1);
  const [randomQuestion, setRandomQuestion] = useState("");
  const [tmp, setTmp] = useState(1);
  const [showLastAnswer, setShowLastAnswer] = useState(false);
  const [lastAnswer, setLastAnswer] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [myTurn, setMyTurn] = useState(false);
  const [playerAnswerInput, setPlayerAnswerInput] = useState("");
  const inputRef = useRef(null);
  let serverTimeOffset = 0;

  // hier bugg falls alle spielr 0 leben
  // da playerCounter mit einem out of bounce index für players initialisert wird
  const [playerCounter, setPlayerCounter] = useState(
    getNextAlivePlayerIndex(players, -1)
  );

  const [playerName, setPlayerName] = useState("");
  const [intervalID, setIntervalID] = useState(0);
  const [startedAt, setStartedAt] = useState(null);

  const myTurnRef = useRef(myTurn);
  const randomQuestionRef = useRef(randomQuestion);
  const isCreatorRef = useRef(isCreator);
  const lastAnswerRef = useRef(lastAnswer);

  const roundRef = useRef(round);
  const [isQuestionAnimationComplete, setIsQuestionAnimationComplete] =
    useState(false);

  // Flag, um den ersten Call zu überspringen
  // das isInitialLoad zeug braucht man da onValue nicht nur bei änderung sondern auch beim initialisieren des listeners ausgeführt wird.
  //beim ersten rendern des Components (VotingNumber= 1) ist allerdings noch nichts in der datenbank deswegne wird auch onValue nicht beim initalsieren getrigered sondern nur bei der erstend änderung/hinzufügung
  const isInitialLoad1 = useRef(votingNumber <= 1 ? false : true);
  const isInitialLoad2 = useRef(votingNumber <= 1 ? false : true);
  const isInitialLoad3 = useRef(votingNumber <= 1 ? false : true);
  const isInitialLoad4 = useRef(votingNumber <= 1 ? false : true);

  // for onDisconnect variables
  const prevPlayerRef = useRef(null);

  const [currentUid, setCurrentUid] = useState(uid);
  const currentUidRef = useRef(currentUid);

  const prevRefs = useRef({ playerLivesRef: null, creatorRef: null });

  useEffect(() => {
    if (count == 0) {
      clockSound.stop();
    }
  }, [count]);

  useEffect(() => {
    const newAnswerRef = ref(db, "rooms/" + roomID + "/questions/playerAnswer");
    if (myTurn) {
      if (inputRef.current) {
        inputRef.current.focus();
      }

      onDisconnect(newAnswerRef).update({
        Answer: "no answer",
        // mache das damit jeder spieler einen anderen dummy setzt falls zwei mal hinter einander leaven
        dummy: -1 * getPlayerIndexById(uid, players) - 1,
      });
    } else {
      onDisconnect(newAnswerRef).cancel();
    }
  }, [myTurn]);

  useEffect(() => {
    currentUidRef.current = currentUid;
    if (currentUid) {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
      const newPlayerRef = ref(
        db,
        "rooms/" + roomID + "/players/" + currentUid
      );
      onDisconnect(newPlayerRef).update({
        lives: 0,
        isCreator: false,
        online: false,
      });
      prevPlayerRef.current = newPlayerRef;
    }

    return () => {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
    };
  }, [currentUid]);

  useEffect(() => {
    myTurnRef.current = myTurn;
  }, [myTurn]);

  useEffect(() => {
    randomQuestionRef.current = randomQuestion;
  }, [randomQuestion]);

  useEffect(() => {
    isCreatorRef.current = isCreator;
  }, [isCreator]);

  useEffect(() => {
    lastAnswerRef.current = lastAnswer;
  }, [lastAnswer]);

  useEffect(() => {
    roundRef.current = round;
  }, [round]);

  const avatars = useMemo(() => {
    return players.map((player) =>
      createAvatar(bigSmile, {
        size: 50,
        seed: player.playerName,
        backgroundColor: [],
      }).toDataUri()
    );
  }, [players]);

  const timer = (time, setCount, startAt, serverTimeOffset) => {
    let soundPlayed = false;
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        setIntervalID(interval);
        //console.log("intervalid1: " + intervalID);
        const timeLeft =
          time * 1000 - (Date.now() - startAt - serverTimeOffset);
        if (timeLeft <= 0) {
          clockSound.stop();
          clearInterval(interval);
          setCount(0.0);
          resolve(); // Resolve the Promise when the timer completes
        } else {
          //setCount(parseFloat(`${Math.floor(timeLeft/1000)}.${timeLeft % 1000}`));
          //setCount(Math.floor(timeLeft / 1000)); // Zeile geändert
          const secondsLeft = timeLeft / 1000;
          if (secondsLeft <= 4 && !soundPlayed) {
            clockSound.play();
            soundPlayed = true;
          }
          setCount(secondsLeft.toFixed(2)); // Setze die Zeit mit zwei Nachkommastelle
        }
      }, 100);
    });
  };

  useEffect(() => {
    if (listener1Ref.current) {
      window.removeEventListener("popstate", listener2Ref.current);
    }
    if (listener3Ref.current) {
      window.removeEventListener("popstate", listener3Ref.current);
    }
    if (listener4Ref.current) {
      window.removeEventListener("popstate", listener4Ref.current);
    }
    if (listener5Ref.current) {
      window.removeEventListener("popstate", listener5Ref.current);
    }

    // onDisconnects werden nur beim zurück navigieren getrigered wenn man von der Seite runter navigiert
    // um auch handere zurück pfeile zu handeln dieses zeug
    // popstate wird allerdings auch bei vorwärtspfeil getrigered was mögliche buggs verursacht
    // man braucht listener1Ref um in einem anderen Component diesen listener canceln zu können
    // man kann nicht in diesem komponent canceln da sonst beim zurück pfeil gecancelt wird bevor handePopstate ausgeführt wird
    function handlePopState2() {
      const newPlayerRef = ref(
        db,
        "rooms/" + roomID + "/players/" + currentUidRef.current
      );
      update(newPlayerRef, {
        lives: 0,
        isCreator: false,
        online: false,
      });
      if (myTurnRef.current) {
        const currEmptyAnswer = {
          Answer: "no answer",
          // mache das damit jeder spieler einen anderen dummy setzt falls zwei mal hinter einander leaven
          dummy: -1 * getPlayerIndexById(uid, players) - 1,
        };
        set(
          ref(db, "rooms/" + roomID + "/questions/playerAnswer"),
          currEmptyAnswer
        );
      }
      setShouldJoin(true);
      setInLobby(true);
    }

    listener2Ref.current = handlePopState2;

    // Füge den Event-Listener hinzu
    window.addEventListener("popstate", handlePopState2);

    const timeOffRef = ref(db, ".info/serverTimeOffset");
    const unsubscribeTimeOffListener = onValue(timeOffRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        serverTimeOffset = snapshot.val();
      }
    });

    //hier brauch man  isInitialLoad eigentlich auch
    // aber der callback vom initalisieren und ändern von startedgAt in der db ist sehr shcnell hint einander. Da die callbacks von den listenern asynchron sind ist das alles vebruggt weil es parallel ausgeführt wird und funktioniert nicht mit isInitialLoad.
    //deswegen andere Lösung: remove startetAt am ende jedes mal.
    // Dadurch wird beim initialisieren nicht in der db kein startedAt gefunden und der listener wird nur einmal getrigered (trigger vom initialiseren wird verhindert).
    // Es ist so als würde der component jedes mal zum ersten mal gerendert werden.
    const startAtRef = ref(db, "rooms/" + roomID + "/status/startAt");
    const unsubscribeStartAtListener = onValue(startAtRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setStartedAt(data);
      }
    });
    //hier brauch man  isInitialLoad eigentlich auch
    // aber der callback vom initalisieren und ändern von startedgAt in der db ist sehr shcnell hint einander. Da die callbacks von den listenern asynchron sind ist das alles vebruggt weil es parallel ausgeführt wird und funktioniert nicht mit isInitialLoad.
    //deswegen andere Lösung: remove startetAt am ende jedes mal.
    // Dadurch wird beim initialisieren nicht in der db kein startedAt gefunden und der listener wird nur einmal getrigered (trigger vom initialiseren wird verhindert).
    // Es ist so als würde der component jedes mal zum ersten mal gerendert werden.
    const startGameInDbRef = ref(db, "rooms/" + roomID + "/status/startGame");
    const unsubscribestartGameInDbRefListener = onValue(
      startGameInDbRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          // starte gameLoop1
          setStartGame(data + 1);
        }
      }
    );

    //const AnswerInDBRef = ref(db, 'rooms/' + roomID + '/history'); // Pfad zum ausgewählten Feld in der Realtime Database
    const AnswerInDBRef = ref(
      db,
      "rooms/" + roomID + "/questions/playerAnswer"
    ); // Pfad zum ausgewählten Feld in der Realtime Database

    // das isInitialLoad zeug braucht man da onValue nicht nur bei änderung sondern auch beim initialisieren des listeners ausgeführt wird.
    // ohne das ist ein bugg das voting direkt übersprungen wird, dieser bugg tritt immer außer beim ersten voting auf da beim ertsen voting backToGame noch nciht in der Datenbank ist
    //beim ersten rendern (VotingNumber= 1) ist allerdings noch nihcts in der datenbank deswegne wird auch onValue nicht beim initalsieren ausgeführt
    const unsubscribeAnswerInDBListener = onValue(AnswerInDBRef, (snapshot) => {
      if (isInitialLoad2.current) {
        isInitialLoad2.current = false;
        return;
      }
      const data = snapshot.val();
      if (data) {
        // erhöhe um andere useEffect zu triggern
        // aktuellisiere damit nicht zwei mal das selbe in firebase database geschrieben wird und firebase onValueTriggered
        dummyCounterRef.current = data.dummy;
        setAnswerInDB((currentTmp) => {
          return currentTmp + 1;
        });
        setLastAnswer(data.Answer);
        setShowLastAnswer(true);
      }
    });

    const playersRef = ref(db, "rooms/" + roomID + "/players");
    const unsubscribePlayersListener = onValue(playersRef, (snapshot) => {
      const data = snapshot.val();
      // hier brauch isInitaialLoad oder ähnliches nicht da egal ob es zu oft abgefragt würd, schadet nicht
      // möglichkeit um zu minimieren für später indem man den initlia call verhindert
      if (data) {
        const playersArray = Object.entries(data).map(
          ([playerID, playerData]) => {
            const votedBy = playerData.votedBy
              ? Object.keys(playerData.votedBy)
              : [];
            return { playerID: playerID, ...playerData, votedBy: votedBy };
          }
        );
        setPlayers(playersArray);

        // detailed comments about this in lobby component at palyers listener
        if (getPlayerById(uid, playersArray).isCreator) {
          setIsCreator(false);
        } else {
          setIsCreator(true);
        }

        if (!creatorExists(playersArray)) {
          const nextAlivePlayerId = getAlivePlayers(playersArray)[0].playerID;

          set(
            ref(
              db,
              "rooms/" + roomID + "/players/" + nextAlivePlayerId + "/isCreator"
            ),
            true
          );
        }

        //if only 2 player remain go instant to WinnerScreen
        const alivePlayers = getAlivePlayers(playersArray);

        if (alivePlayers.length == 2) {
          if (!isCreatorRef.current) {
            //wichtige zeile, siehe Listener von startedVotingAt
            remove(ref(db, "rooms/" + roomID + "/status/startedVotingAt"));
          }
          setFinalists(alivePlayers);
          setShowFinalScreen(true);
        } else if (alivePlayers.length <= 1) {
          if (!isCreatorRef.current) {
            //wichtige zeile, siehe Listener von startedVotingAt
            remove(ref(db, "rooms/" + roomID + "/status/startedVotingAt"));
          }
          setWinner(alivePlayers[0]);
          setShowWinnerScreen(true);
        }
      }
    });

    const questionRef = ref(db, "rooms/" + roomID + "/questions/question");
    const unsubscribeQuestionListener = onValue(questionRef, (snapshot) => {
      /*
      if (isInitialLoad3.current) {
        isInitialLoad3.current = false; 
        return; 
      }
        */
      const data = snapshot.val();
      if (data) {
        const helperFunction1 = async () => {
          setRandomQuestion(""); // damit im neuen nicht kurz noch die alte frage steht
          await wait(1000);
          setRandomQuestion(data);

          //start gameLoop2
          await wait(data.length * 40 + 100);

          if (!isCreatorRef.current) {
            await set(
              ref(db, "rooms/" + roomID + "/status/startAt"),
              serverTimestamp()
            );
          }
        };

        helperFunction1();
      }
    });

    const correctAnswerRef = ref(
      db,
      "rooms/" + roomID + "/questions/correctAnswer"
    ); // Pfad zum ausgewählten Feld in der Realtime Database
    const unsubscribeCorrectAnswerListener = onValue(
      correctAnswerRef,
      (snapshot) => {
        if (isInitialLoad4.current) {
          isInitialLoad4.current = false; // Flag zurücksetzen
          return; // Skip the first call
        }
        const data = snapshot.val();
        if (data) {
          setCorrectAnswer(data); // Aktualisiere den Wert im State mit dem Wert aus der Datenbank
        }
      }
    );

    if (!isCreatorRef.current) {
      // starte game
      set(ref(db, "rooms/" + roomID + "/status/startGame"), startGame);
    }

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      clockSound.stop();

      unsubscribeTimeOffListener();
      unsubscribeStartAtListener();
      unsubscribeAnswerInDBListener();
      unsubscribeQuestionListener();
      unsubscribeCorrectAnswerListener();
      unsubscribePlayersListener();
    };
  }, []);

  useEffect(() => {
    const gameLoop1 = async () => {
      if (!isFirstGameInit) {
        setShowLastAnswer(false);
        // manage wer dran ist und runden
        // das verursacht wahrscheinlich manchmal ein bug falls die timer zeit klein ist weil setPlayerCounter asynchron ist und deswegen nicht schnell genug aktuellisiert bevoir es wieder beim if statement ist. Der Bugg ewnsteht also durch das oberste if statement und lässt sich durch ein weiteres useEffect() verhindern
        if (playerNumber <= getNextAlivePlayerIndex(players, playerCounter)) {
          setPlayerCounter(getNextAlivePlayerIndex(players, -1));
          if (round + 1 > rounds) {
            //wichtige zeile, siehe Listener von startedAt und startGame
            remove(ref(db, "rooms/" + roomID + "/status/startAt"));
            remove(ref(db, "rooms/" + roomID + "/status/startGame"));
            remove(ref(db, "rooms/" + roomID + "/questions/question"));

            setShowVoting(true);
            return;
          } else {
            setRound((currentRound) => {
              return currentRound + 1;
            });
          }
        } else {
          const nextAlivePlayer = getNextAlivePlayerIndex(
            players,
            playerCounter
          );
          setPlayerCounter(nextAlivePlayer);
        }
      }

      if (isFirstGameInit) {
        setIsFirstGameInit(false);
      }

      // sucht fragen raus
      if (!isCreatorRef.current) {
        const tmpRandomQuestion = getRandomQuestion(
          randomQuestionRef.current,
          language
        );

        await set(
          ref(db, "rooms/" + roomID + "/questions/question"),
          tmpRandomQuestion.question
        );
        await set(
          ref(db, "rooms/" + roomID + "/questions/correctAnswer"),
          tmpRandomQuestion.answer
        );
      }
    };

    if (startGame >= 1) {
      gameLoop1();
    }
  }, [startGame]);

  useEffect(() => {
    const gameLoop2 = async () => {
      // checkt ob ich dran bin
      setMyTurn(false);
      if (players[playerCounter].playerID === uid) {
        setMyTurn(true);
      }
      await timer(questionTime, setCount, startedAt, serverTimeOffset);
      if (myTurnRef.current) {
        sendAnswer();
      }
    };
    // serverTimestamp() gibt 2 triggers vom listener mit der lokalen und mit der server Zeit deswegen braucht man das
    if (startedAt !== null) {
      if (!isCreatorRef.current) {
        if (tmp === 2) {
          gameLoop2();
          setTmp((currentTmp) => {
            return currentTmp + -1;
          });
        } else {
          setTmp((currentTmp) => {
            return currentTmp + 1;
          });
        }
      } else {
        gameLoop2();
      }
    }
  }, [startedAt]);

  useEffect(() => {
    // aktuelisiere Spieler der dran sit
    setPlayerName(players[playerCounter].playerName);
  }, [playerCounter]);

  useEffect(() => {
    if (answerInDB !== 0) {
      const finishRound = async () => {
        setCount(0);

        // unterbricht den timer falls sendAnswer augerufen wurde ebvor die ZEit vorbei ist
        // der timer wird zwar beendet aber das promise wird nicht aufgelöst, wodurch der code nach dem timer
        // nie ausgeführt wird
        clearInterval(intervalID);
        // wdarte 5s bevor nächster spieler dran ist
        // muss außerhalb des if statements sein, da sonst bugg mit onDisconnect wenn der creator währen d des wartends leaved
          console.log(lastAnswerRef.current.length * 75 + 3500);
          await wait(lastAnswerRef.current.length * 100 + 3500);

        if (!isCreatorRef.current) {
          await set(
            ref(db, "rooms/" + roomID + "/status/startGame"),
            startGame
          );
        }
      };

      finishRound();
    }
  }, [answerInDB]);

  const sendAnswer = async () => {
    // später wird sich drum gekümmert wer dran ist nur hier erstmal sicherstellen das man nciht zweimal den button drücken kann
    // brauche das if statenebt da sonst bugg wenn nur ein spieler alive
    if (getAlivePlayers(players, roomID).length >= 2) {
      setMyTurn(false);
    }

    let tmpAnswer;
    if (inputRef.current) {
      tmpAnswer = inputRef.current.value;
    } else {
      tmpAnswer = "";
    }

    const personalHistory = {
      Question: randomQuestionRef.current, // Passe den Nachrichtentext an
      Answer: tmpAnswer,
    };

    // history für voting hover
    const emptyPersonalHistory = {
      Question: randomQuestionRef.current, // Passe den Nachrichtentext an
      Answer: "no answer",
    };

    if (tmpAnswer === null || tmpAnswer === "") {
      set(
        ref(
          db,
          "rooms/" +
            roomID +
            "/history/Voting" +
            votingNumber +
            "/" +
            uid +
            "/question" +
            roundRef.current
        ),
        emptyPersonalHistory
      );
    } else {
      set(
        ref(
          db,
          "rooms/" +
            roomID +
            "/history/Voting" +
            votingNumber +
            "/" +
            uid +
            "/question" +
            roundRef.current
        ),
        personalHistory
      );
    }

    // erhöhe AnswerCounter
    // mache das da beim onDisconnect dummy auf negativen wert gsetzt wird,
    // negative werte sind shclecht weil es zu porblemen mit anderen onDisconencts kommen kann
    //diese sind ebenfalls 0
    dummyCounterRef.current = Math.max(0, dummyCounterRef.current);
    dummyCounterRef.current += 1;

    const currAnswer = {
      Answer: tmpAnswer,
      dummy: dummyCounterRef.current,
    };

    const currEmptyAnswer = {
      Answer: "nothing",
      dummy: dummyCounterRef.current,
    };
    if (tmpAnswer === null || tmpAnswer === "") {
      await set(
        ref(db, "rooms/" + roomID + "/questions/playerAnswer"),
        currEmptyAnswer
      );
    } else {
      await set(
        ref(db, "rooms/" + roomID + "/questions/playerAnswer"),
        currAnswer
      );
    }
    //delete answer from input field
    setPlayerAnswerInput("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && myTurn) {
      sendAnswer();
    }
  };
  return (
    <div className="game-container">
      <div className="Game-heads-up-display">
          <TimerAnimation count={count} questionTime={questionTime} className={"Game-timer"} />

        <h1 className="Game-heads-up-display-rounds">
          {round}/{rounds}
        </h1>
      </div>
      <div className="Game-player-list">
        {players &&
          players.map((player, index) => (
            <div
            className={`Game-player ${player.lives <= 0 ? "grayed-out" : ""}`}              key={player.playerID}
              style={{
                outline:
                  player.playerID === uid ? "0.6vh solid #28B274" : "none",
            
              }}
            >
              {player.isCreator && (
                <img className="game-crown" src={creatorCrown} />
              )}
              <div className="game-avatar-div">
                <img
                  className="game-avatar"
                  src={avatars[index]}
                  alt="Avatar"
                />
                <img
                  className="game-avatar-background"
                  src={avatarBackground2}
                />
              </div>
              <h3 className="Game-player-name" style={{ position: "relative" }}>
                {player.playerName}
              </h3>
              {index === playerCounter && (
                <img className="Game-player-arrow" src={playerArrow} />
              )}
              <br />
              {renderBrains(player.lives)}
            </div>
          ))}
      </div>
      <div className="Game-question-div">
        <RandomQuestion className="Game-question" question={randomQuestion}               writingSpeed={40}
 />
      </div>
      <div className="Game-answer-area">
        <div className="Game-my-turn-text-div">
          <img
            className="Game-question-area-avatar"
            src={avatars[playerCounter]}
            alt="Avatar"
          />
          <h1 className="Game-question-area-player-name">{playerName}</h1>
          <h1 className="Game-question-area-answers-text">answers...</h1>
        </div>
        <div className="Game-input-div">
          {myTurn && (
            <input
              className="Game-question-area-input"
              type="text"
              id="meinInputFeld"
              value={playerAnswerInput}
              placeholder="write!"
              onKeyDown={handleKeyDown}
              maxLength="28"

              ref={inputRef}
              onChange={(e) => {
                setPlayerAnswerInput(e.target.value);
              }}
            ></input>
          )}
          {showLastAnswer && (
            <>
              <LastAnswer
                className="Game-question-area-player-answer"
                text={lastAnswer}
                typingSound={typingSound}
                writingSpeed={100}

              />
            </>
          )}
        </div>
        {showLastAnswer && (
          <>
            <p className="Game-question-area-correct-answer">
              correct answer: {correctAnswer}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
