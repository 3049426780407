import { db, auth, uid } from "../firebase-config.js";
import {
  onValue,
  getDatabase,
  ref,
  set,
  push,
  hasChild,
  exists,
  get,
  serverTimestamp,
  off,
  remove,
  onDisconnect
} from "firebase/database";
import { AuthJoin } from "./AuthJoin";
import { VolumeSlider } from "./sections/VolumeSlider";
import { getPlayerById, getFirstDifferentPlayerIndex, creatorExists} from "../utilities/helperFunctions.js";
import { Game } from "./Game";
import { useState, useRef, useEffect, useContext } from "react";
import {onAuthStateChanged} from "firebase/auth"

import { AppContext } from "../App";
import "../styles/Lobby.css";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { SoundControl } from "./sections/LastAnswer";

import { timer,initializePlayerLives } from "../utilities/gameFunctions";
import { createAvatar } from "@dicebear/core";
import { avataaars , lorelei } from "@dicebear/collection";
import { dylan, bigSmile,  } from '@dicebear/collection';
import avatarBackground2 from "../styles/images/avatarBackground2.svg";
import creatorCrown from "../styles/images/creatorCrown.svg";

import { useMemo } from "react";

export const Lobby = ({volume, setVolume, roomID, setInLobby, isCreator, lives, rounds,setRounds, setIsCreator, questionTime,votingTime,playerNumber,setPlayerNumber, players ,setPlayers, shouldJoin, setShouldJoin, language, setLanguage, setLives, setQuestionTime, setVotingTime, 

  listener1Ref,
  listener2Ref,
  listener3Ref,
  listener4Ref,
  listener5Ref,
  playerJoinSound,
  isFirstSound,
  setIsFirstSound,



}) => {

  //const { shouldJoin, setShouldJoin } = useContext(AppContext);
  const [isAuth, setIsAuth] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [count, setCount] = useState(6.0);
  const [startedAt, setStartedAt] = useState(null);
  let timeLeft = 3;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [currentUid, setCurrentUid] = useState(uid);
  const currentUidRef = useRef(currentUid);

  // normal refs für aktuelel version in logik

  
  const playerUseRef = useRef(players);
  const playerNumberRef = useRef(0);

  // for onDisconnect variables
  const prevPlayerRef = useRef(null);
  const prevCreaterRef = useRef(null);
  const shouldJoinRef = useRef(shouldJoin);


  

  useEffect(() => {
    shouldJoinRef.current = shouldJoin;
  }, [shouldJoin]);

  useEffect(() => {
    playerUseRef.current = players;
  }, [players]);
  

  useEffect(() => {
    console.log("isFirstSound: " + isFirstSound  )
    console.log("isCreator: " + isCreator  )
    console.log("playerNumber > playerNumberRef.current: "  + (playerNumber > playerNumberRef.current))


    if((playerNumber > playerNumberRef.current) && (!isFirstSound || !isCreator)){
    

      playerJoinSound.play();
    }
    if(playerNumber > playerNumberRef.current){
        // brauche if statement weil beim ersten aurufen dises useEffect playerNumber > playerNumberRef.current == false ist und danach kommt erst der aufruf der durch den flag geskipt werden muss
        // ist also eignetlich isSecondSound
        setIsFirstSound(false);

    }
    playerNumberRef.current = playerNumber
  }, [playerNumber]);

  useEffect(() => {
    currentUidRef.current = currentUid;
    if (currentUid) {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
      const playerRef = ref(db, "rooms/" + roomID + "/players/" + currentUid);
      onDisconnect(playerRef).remove(); 
      prevPlayerRef.current = playerRef; 
    }
    return () => {
      if (prevPlayerRef.current) {
         onDisconnect(prevPlayerRef.current).cancel();
      }
    }
  }, [currentUid]);
  

  
 


  useEffect(() => {
    if(listener2Ref.current){
      window.removeEventListener('popstate', listener2Ref.current);

    }
    if(listener3Ref.current){
      window.removeEventListener('popstate', listener3Ref.current);

    }
    if(listener4Ref.current){
      window.removeEventListener('popstate', listener4Ref.current);

    }
    if(listener5Ref.current){
      window.removeEventListener('popstate', listener5Ref.current);

    }

    // onDisconnects werden nur beim zurück navigieren getrigered wenn man von der Seite runter navigiert
    // um auch handere zurück pfeile zu handeln dieses zeug
    // popstate wird allerdings auch bei vorwärtspfeil getrigered was mögliche buggs verursacht
    // man braucht listener1Ref um in einem anderen Component diesen listener canceln zu können
    // man kann nicht in diesem komponent canceln da sonst beim zurück pfeil gecancelt wird bevor handePopstate ausgeführt wird
    function handlePopState1() {

      remove(ref(db, "rooms/" + roomID + "/players/" + currentUidRef.current));
      setShouldJoin(true);
    }

    listener1Ref.current = handlePopState1;
    
    // Füge den Event-Listener hinzu
    window.addEventListener('popstate', handlePopState1);

    /*
    const testRef1 = ref(db, "rooms/" + roomID + "/players/" + currentUid);
    const testRef2 = ref(db, "rooms/" + roomID + "/players/" + currentUid + "/isCreator");
    
    const disconnect1 = onDisconnect(testRef1);
    const disconnect2 = onDisconnect(testRef2);
  
    disconnect1.remove();
    disconnect2.set(true);
    */
    // Hier sicherstellen, dass cancel() nur für testRef2 verwendet wird, 
    // wenn keine anderen wichtigen onDisconnect-Operationen auf testRef1 sind.
    //disconnect2.cancel();

 
      // Variablen für die Unsubscribe-Funktionen
    let unsubscribePlayersListener;
    let unsubscribeLivesListener;
    let unsubscribeRoundsListener;
    let unsubscribeQuestionTimeListener;
    let unsubscribeVotingTimeListener;
    let unsubscribeHasStartedListener;

    // mache alles erst wenn user authenzifiert ist um probleme mut security rules zu umgehen
    const unsubscribeonAuthStateChanged = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUid(user.uid);
 


    
    const livesRef = ref(db, "rooms/" + roomID + "/settings/lives");
    

    //console.log(count);
    //console.log(shouldJoin);
    // join Prozess
    if (shouldJoinRef.current) {
      setIsCreator(true);
      // console.log("jetzt aber ich will schlafen")
    }


    
      const playersRef = ref(db, "rooms/" + roomID + "/players");
      unsubscribePlayersListener = onValue(playersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const playersArray = Object.entries(data).map(([playerID, playerData]) => {
            const votedBy = playerData.votedBy ? Object.keys(playerData.votedBy) : [];
            return { playerID: playerID, ...playerData, votedBy: votedBy };
          });
          if(playersArray.length > playerUseRef.current.length){
            //playerJoinSound.play();
          }
          setPlayers(playersArray);
          setPlayerNumber(playersArray.length);
          if(playersArray.length > 1){
            set(ref(db, "rooms/" + roomID + "/status/playerNumber"), playersArray.length);
          }
          if(getPlayerById(uid, playersArray).isCreator){
            setIsCreator(false);
          }

          // um sicherzu stellen das wenn ein Creator leavt, das ein neuer ausgewählt wird
          // ist super innefektiv da jeder spieler diesen set setzt
          // potential zum optimieren in zukunft
          // das darunter raus kommentierte wäre besser aber klappt nicht weil:
          // https://stackoverflow.com/questions/78928214/unexpected-behavior-with-firebase-realtime-database-ondisconnect-and-cancel-in-n

          // man muss sortieren da sonst manchmal bugg wenn gleicher name
          // brauch man wahrschebkich doch nciht
          const sortedPlayers = playersArray.sort((a, b) => a.playerID.localeCompare(b.playerID));
          if (!creatorExists(sortedPlayers)) {
              set(ref(db, "rooms/" + roomID + "/players/" + sortedPlayers[0].playerID + "/isCreator"), true);
          }

          // if player isCreator setze onDisconnect neuen Creator
          /*
          if(getPlayerById(uid, playersArray).isCreator) {
            setIsCreator(false);

            if (prevCreaterRef.current) {
              console.log("cancle: " + prevCreaterRef.current)
              onDisconnect(prevCreaterRef.current).cancel();
            }
            
            const newCreatorID = playersArray[getFirstDifferentPlayerIndex(uid, playersArray)].playerID
            const newCreaterRef = ref(db, "rooms/" + roomID + "/players/" + newCreatorID + "/isCreator");
            console.log("setze on Disconnect: "+ newCreaterRef)

            onDisconnect(newCreaterRef).set(true);
            prevCreaterRef.current = newCreaterRef;            
          } 
          */
      

        }
      });

    // sync game Settings
    //const livesRef = ref(db, "rooms/" + roomID + "/settings/lives"); // Pfad zum ausgewählten Feld in der Realtime Database
    unsubscribeLivesListener = onValue(livesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setLives(data); // Aktualisiere den Wert im State mit dem Wert aus der Datenbank
      }
    });
    const roundsRef = ref(db, "rooms/" + roomID + "/settings/rounds"); // Pfad zum ausgewählten Feld in der Realtime Database
    unsubscribeRoundsListener = onValue(roundsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setRounds(data); // Aktualisiere den Wert im State mit dem Wert aus der Datenbank
      }
    });
    const questionTimeRef = ref(db, "rooms/" + roomID + "/settings/questionTime"); // Pfad zum ausgewählten Feld in der Realtime Database
     unsubscribeQuestionTimeListener = onValue(questionTimeRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setQuestionTime(data); 

      }
    });
    const votingTimeRef = ref(db, "rooms/" + roomID + "/settings/votingTime");
    unsubscribeVotingTimeListener = onValue(votingTimeRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setVotingTime(data);

      }
    });
    const languageRef = ref(db, "rooms/" + roomID + "/settings/language");
    const unsubscribeLanguageListener = onValue(languageRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setLanguage(data);

      }
    });
    const hasStartedRef = ref(db, "rooms/" + roomID + "/status/hasStarted");
    unsubscribeHasStartedListener = onValue(hasStartedRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {


        if(!shouldJoinRef.current){
          setInLobby(false); 
        }



      }
    });



    if (!isCreator) {
        // entferne damit es nicht beim rendern gelesen, wird.
        // könnte auc hdurch ein isInitialLoad geregelt werden 
        // möglichkeit zum minimieren von firebase use 
       remove(ref(db, `rooms/${roomID}/status/backToLobby`))
    }
    }
  });


        // Cleanup function to remove the listener when the component unmounts
//ALARM WARUM NICHT GECALLED?
    return () => {
      unsubscribePlayersListener();
      unsubscribeLivesListener();
      unsubscribeRoundsListener();
      unsubscribeQuestionTimeListener();
      unsubscribeVotingTimeListener();
      unsubscribeHasStartedListener();
      //window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // erstelle avatare
  // https://www.dicebear.com/styles/avataaars/
  const avatars = useMemo(() => {
    return players ? players.map((player) =>
      createAvatar(bigSmile , {
        size: 50,
        seed: player.playerName,
        backgroundColor: []
      }).toDataUri()
    ) : [];
  }, [players]);

  /*
  useEffect(() => {
    if(playerNumber > 1){
      set(ref(db, "rooms/" + roomID + "/status/playerNumber"), playerNumber);
    }
  }, [playerNumber]);
  */

  // bei release das davor packen https://
  const copyToClipboard = () => {
    const copyText = `https://${window.location.hostname}/room/${roomID}`;
    navigator.clipboard.writeText(copyText);
  };

  const handleLivesChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/lives"), e.target.value);
    setLives(e.target.value);
  };

  const handleRoundsChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/rounds"), e.target.value);
    setRounds(e.target.value);
  };

  const handleQuestionTimeChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/questionTime"), e.target.value);
    setQuestionTime(e.target.value);
  };

  const handleVotingTimeChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/votingTime"), e.target.value);
    setVotingTime(e.target.value);
  };

  const handleLanguageChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/language"), e.target.value);
    setLanguage(e.target.value);
  };

  
  const handleGameStart  = async (e) => {
    setIsButtonDisabled(true);
    if(!isCreator){
      await initializePlayerLives (roomID, lives, players, db);
      set(ref(db, "rooms/" + roomID + "/lastActivity"), serverTimestamp());
      set(ref(db, "rooms/" + roomID + "/status/hasStarted"), true);
    }
  };

  // mit uid anstat isAuth ist eigentlic besser
  if (shouldJoin) {
    return (
      <div>
        <AuthJoin roomID={roomID} setIsAuth={setIsAuth} shouldJoin={shouldJoin} setShouldJoin={setShouldJoin} />
      </div>
    );
  }

  if (!shouldJoin) {
    return (
      <div className="lobby-container">
      <div className="Lobby-settings-box">
        <form id="gameSettingsForm">
          
          <div className="form-group lives">
            <label htmlFor="livesInput">Lives</label>
            <select
              id="livesInput"
              name="lives"
              required
              disabled={isCreator}
              value={lives}
              onChange={handleLivesChange}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          
          <div className="form-group rounds">
            <label htmlFor="roundsInput">Rounds</label>
            <select
              id="roundsInput"
              name="rounds"
              required
              disabled={isCreator}
              value={rounds}
              onChange={handleRoundsChange}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          
          <div className="form-group questionTime">
            <label htmlFor="timePerQuestionInput">Question Time</label>
            <select
              id="timePerQuestionInput"
              name="timePerQuestion"
              required
              disabled={isCreator}
              value={questionTime}
              onChange={handleQuestionTimeChange}
            >
              <option value="10">10s</option>
              <option value="20">20s</option>
              <option value="30">30s</option>
              <option value="45">45s</option>
              <option value="60">60s</option>
              <option value="90">90s</option>
              <option value="120">120s</option>
            </select>
          </div>
          
          <div className="form-group votingTime">
            <label htmlFor="votingTimeInput">Voting Time</label>
            <select
              id="votingTimeInput"
              name="votingTime"
              required
              disabled={isCreator}
              value={votingTime}
              onChange={handleVotingTimeChange}
            >
              <option value="15">15s</option>
              <option value="30">30s</option>
              <option value="45">45s</option>
              <option value="60">60s</option>
              <option value="90">90s</option>
              <option value="120">120s</option>
              <option value="180">180s</option>
            </select>
          </div>
          
          <div className="form-group language">
            <label htmlFor="languageInput">Language</label>
            <select
              id="languageInput"
              name="languageInput"
              required
              disabled={isCreator}
              value={language}
              onChange={handleLanguageChange}
            >
              <option value="english">English</option>
              <option value="german">German</option>
            </select>
          </div>
    
        </form>
      </div>
        {playerNumber < 3 && (
          <p className="Lobby-minimal-playernumber">
            You need at least 3 players to start the game
          </p>
        )}
        <div className="Lobby-player-list">
          {players &&
            players.map((player, index) => (
              <div
                className="Lobby-player"
                key={player.playerID}
                style={{
                  outline:
                    player.playerID === uid ? "0.6vh solid #28B274" : "none",
                }}
              >
                                    {player.isCreator && (
                          <img className="lobby-crown"  src={creatorCrown} />
                      )}
                <div className="lobby-avatar-div">
                  <img
                    className="lobby-avatar"
                    src={avatars[index]}
                    alt="Avatar"
                  />
                  <img
                    className="lobby-avatar-background"
                    src={avatarBackground2}
                  />
                </div>
                <h3
                  className="Lobby-player-name"
                  style={{ position: "relative" }}
                >
                                     {player.playerName}
  
                </h3>
              </div>
            ))}
        </div>
  
         
            <button
              className="lobby-start-button"
              onClick={handleGameStart}
              disabled={isCreator || playerNumber < 3 || isButtonDisabled}
            >
              Start
            </button>
          
           <p className="lobby-invitation-link">Invitation Link</p>  
          <button className="lobby-copy-button" onClick={copyToClipboard}>Copy</button>
      </div>
    );
  };
}