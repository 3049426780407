import { useState, useEffect, useRef } from "react";

export const TimerAnimation = ({ questionTime, count, className }) => {
    const [animationPercent, setAnimationPercent] = useState(100); 
    const canvasRef = useRef(null);

    useEffect(() => {
        if (count > 0) {
            setAnimationPercent((count / questionTime) * 100);
        } else {
            setAnimationPercent(0); 
        }
    }, [count, questionTime]);

     useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const radius = canvas.width / 2;
        context.clearRect(0, 0, canvas.width, canvas.height); 

        context.beginPath();
        context.arc(radius, radius, radius - 10, 0, Math.PI * 2);
        context.fillStyle = "transparent"; 
        context.fill();
        
        context.beginPath();
        const startAngle = -Math.PI / 2; 
        const endAngle = startAngle + (Math.PI * 2 * (animationPercent / 100));
        context.arc(radius, radius, radius - 10, startAngle, endAngle);
        context.lineTo(radius, radius);
        context.fillStyle = "white"; 
        context.fill();
    }, [animationPercent]);

    return (
        <canvas className={className} ref={canvasRef} width={75} height={75} />
    );
};