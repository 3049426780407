import React, { useState, useEffect } from 'react';
import { Howl } from 'howler';

export const LastAnswer = ({ text, typingSound, writingSpeed, className}) => {
  const [displayText, setDisplayText] = useState('');
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      setDisplayText(text.slice(0, currentIndex + 1));
      typingSound.play(); 

      currentIndex++;

      if (currentIndex >= text.length) {
        clearInterval(interval);
      }
    }, writingSpeed); 

    return () => clearInterval(interval);
  }, [text]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor(prevShowCursor => !prevShowCursor);
    }, 500); // Geschwindigkeit des Cursor
    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <h1 className={className}>
      {displayText}
      {showCursor &&     <span style={{ position: 'relative', bottom: '0.1vw' }}>|</span>
    }
    </h1>
  );
};
