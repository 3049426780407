import { db, auth, uid } from "../firebase-config.js";
import { RandomQuestion } from "./sections/RandomQuestion";
import { LastAnswer } from "./sections/LastAnswer";
import { getRandomEstimationQuestion } from "../utilities/gameFunctions";
import {
  getPlayerIndexById,
  renderBrains,
  getPlayerById,
  creatorExists,
  getNextAlivePlayerIndex,
  getAlivePlayers,
  waitForever,
  wait,
  avatars,
} from "../utilities/helperFunctions.js";
import { VolumeSlider } from "./sections/VolumeSlider";
import { TimerAnimation } from "./sections/TimerAnimation";
import "../styles/Final.css";

import { useState, useEffect, useRef } from "react";
import { createAvatar } from "@dicebear/core";
import { dylan, bigSmile } from "@dicebear/collection";
import { useMemo } from "react";

import {
  remove,
  onValue,
  ref,
  set,
  serverTimestamp,
  onDisconnect,
} from "firebase/database";
import { json } from "react-router-dom";
import creatorCrown from "../styles/images/creatorCrown.svg";
import finalFire from "../styles/images/finalFire.svg";
import avatarBackground2 from "../styles/images/avatarBackground2.svg";

export const Final = ({
  roomID,
  finalist1,
  isCreator,
  language,
  finalist2,
  setShowWinnerScreen,
  setWinner,
  setIsCreator,
  setPlayers,
  players,

  listener1Ref,
  listener2Ref,
  listener3Ref,
  listener4Ref,
  listener5Ref,
  clockSound,
  finalTimerSound,
  typingSound,
}) => {
  const [startFinal, setStartFinal] = useState(0);
  let serverTimeOffset = 0;
  const isCreatorRef = useRef(isCreator);
  const [isButtonDisabled] = useState(false);
  const transitionToLobby = () => {};
  const [playerAnswerInput1, setPlayerAnswerInput1] = useState("");
  const [playerAnswerInput2, setPlayerAnswerInput2] = useState("");

  const inputRef2 = useRef(null);
  const inputRef1 = useRef(null);

  const [randomQuestion, setRandomQuestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [showBothPlayerAnswers, setShowBothPlayerAnswers] = useState(false);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);

  const [finalist1Answer, setFinalist1Answer] = useState(null);
  const [finalist2Answer, setFinalist2Answer] = useState(null);
  const [volume, setVolume] = useState(50);
  const sendAnswer = async () => {};
  const [startedFinalAt, setStartedFinalAt] = useState(null);
  const [showLastAnswerFinalist2] = useState(false);
  const [showLastAnswerFinalist1] = useState(false);
  const [tmp, setTmp] = useState(1);
  const [myTurn1, setMyTurn1] = useState(false);
  const [myTurn2, setMyTurn2] = useState(false);
  const dummyCounterRef = useRef(0);

  const [currentUid, setCurrentUid] = useState(uid);
  const prevPlayerRef = useRef(null);

  const estimationQuestionTime = 25;

  const [count, setCount] = useState(estimationQuestionTime);
  const finalist1AnswerRef = useRef(finalist1Answer);
  const finalist2AnswerRef = useRef(finalist2Answer);
  const correctAnswerRef = useRef(correctAnswer);

  const [intervalID, setIntervalID] = useState(0);
  const [isQuestionAnimationComplete, setIsQuestionAnimationComplete] =
    useState(false);

  useEffect(() => {
    if (count == 0) {
      finalTimerSound.stop();
    }
  }, [count]);

  useEffect(() => {
    finalist1AnswerRef.current = finalist1Answer;
  }, [finalist1Answer]);
  
  useEffect(() => {
    finalist2AnswerRef.current = finalist2Answer;
  }, [finalist2Answer]);

  useEffect(() => {
    correctAnswerRef.current = correctAnswer;
  }, [correctAnswer]);
  

  useEffect(() => {
    const newAnswerRef = ref(
      db,
      "rooms/" + roomID + "/questions/finalPlayerAnswer1"
    );
    if (myTurn1) {
      onDisconnect(newAnswerRef).update({
        Answer: "no answer",
        // mache das damit jeder spieler einen anderen dummy setzt falls zwei mal hinter einander leaven
        dummy: -1 * getPlayerIndexById(uid, players) - 1,
      });
    } else {
      onDisconnect(newAnswerRef).cancel();
    }
  }, [myTurn1]);

  useEffect(() => {
    const newAnswerRef = ref(
      db,
      "rooms/" + roomID + "/questions/finalPlayerAnswer2"
    );
    if (myTurn2) {
      onDisconnect(newAnswerRef).update({
        Answer: "no answer",
        // mache das damit jeder spieler einen anderen dummy setzt falls zwei mal hinter einander leaven
        dummy: -1 * getPlayerIndexById(uid, players) - 1,
      });
    } else {
      onDisconnect(newAnswerRef).cancel();
    }
  }, [myTurn2]);

  useEffect(() => {
    if (currentUid) {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
      const playerRef = ref(db, "rooms/" + roomID + "/players/" + currentUid);
      onDisconnect(playerRef).remove();
      prevPlayerRef.current = playerRef;
    }
    return () => {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
    };
  }, [currentUid]);

  const timer = (time, setCount, startAt, serverTimeOffset) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        setIntervalID(interval);
        //console.log("intervalid1: " + intervalID);
        const timeLeft =
          time * 1000 - (Date.now() - startAt - serverTimeOffset);
        if (timeLeft <= 0) {
          clearInterval(interval);
          setCount(0.0);
          resolve(); // Resolve the Promise when the timer completes
        } else {
          //setCount(parseFloat(`${Math.floor(timeLeft/1000)}.${timeLeft % 1000}`));
          //setCount(Math.floor(timeLeft / 1000)); // Zeile geändert
          const secondsLeft = timeLeft / 1000;
          setCount(secondsLeft.toFixed(2)); // Setze die Zeit mit zwei Nachkommastelle
        }
      }, 100);
    });
  };

  const avatar1 = useMemo(() => {
    return createAvatar(bigSmile, {
      size: 70,
      seed: finalist1.playerName,
      backgroundColor: [],
    }).toDataUri();
  }, [finalist1]);

  const avatar2 = useMemo(() => {
    return createAvatar(bigSmile, {
      size: 70,
      seed: finalist2.playerName,
      backgroundColor: [],
    }).toDataUri();
  }, [finalist2]);

  useEffect(() => {
    isCreatorRef.current = isCreator;
  }, [isCreator]);

  useEffect(() => {
    clockSound.stop();


    if (listener1Ref.current) {
      window.removeEventListener("popstate", listener2Ref.current);
    }
    if (listener2Ref.current) {
      window.removeEventListener("popstate", listener3Ref.current);
    }
    if (listener3Ref.current) {
      window.removeEventListener("popstate", listener4Ref.current);
    }
    if (listener4Ref.current) {
      window.removeEventListener("popstate", listener5Ref.current);
    }

    // onDisconnects werden nur beim zurück navigieren getrigered wenn man von der Seite runter navigiert
    // um auch handere zurück pfeile zu handeln dieses zeug
    // popstate wird allerdings auch bei vorwärtspfeil getrigered was mögliche buggs verursacht
    // man braucht listener1Ref um in einem anderen Component diesen listener canceln zu können
    // man kann nicht in diesem komponent canceln da sonst beim zurück pfeil gecancelt wird bevor handePopstate ausgeführt wird
    function handlePopState5() {
      const newAnswerRef = ref(
        db,
        "rooms/" + roomID + "/questions/finalPlayerAnswer2"
      );

      onDisconnect(newAnswerRef).update({
        Answer: "no answer",
        // mache das damit jeder spieler einen anderen dummy setzt falls zwei mal hinter einander leaven
        dummy: -1 * getPlayerIndexById(uid, players) - 1,
      });
      remove(ref(db, "rooms/" + roomID + "/players/" + currentUid));
      setShowWinnerScreen(true);
    }

    listener5Ref.current = handlePopState5;

    // Füge den Event-Listener hinzu
    window.addEventListener("popstate", handlePopState5);

    const timeOffRef = ref(db, ".info/serverTimeOffset");
    const unsubscribeTimeOffListener = onValue(timeOffRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        serverTimeOffset = snapshot.val();
      }
    });

    //hier brauch man  isInitialLoad eigentlich auch
    // aber der callback vom initalisieren und ändern von startedgAt in der db ist sehr shcnell hint einander. Da die callbacks von den listenern asynchron sind ist das alles vebruggt weil es parallel ausgeführt wird und funktioniert nicht mit isInitialLoad.
    //deswegen andere Lösung: remove startetAt am ende jedes mal.
    // Dadurch wird beim initialisieren nicht in der db kein startedAt gefunden und der listener wird nur einmal getrigered (trigger vom initialiseren wird verhindert).
    // Es ist so als würde der component jedes mal zum ersten mal gerendert werden.
    const startFinalInDbRef = ref(db, "rooms/" + roomID + "/status/startFinal");
    const unsubscribestartGameInDbRefListener = onValue(
      startFinalInDbRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          // starte gameLoop1
          setStartFinal(data + 1);
        }
      }
    );

    const questionRef = ref(db, "rooms/" + roomID + "/questions/finalQuestion");
    const unsubscribeQuestionListener = onValue(questionRef, (snapshot) => {
      /*
         if (isInitialLoad3.current) {
           isInitialLoad3.current = false; 
           return; 
         }
           */
      const data = snapshot.val();
      if (data) {
        const helperFunction1 = async () => {
          setRandomQuestion(""); // damit im neuen nicht kurz noch die alte frage steht
          await wait(1000);
          setRandomQuestion(data);

          //start gameLoop2
          await wait(data.length * 50 + 100);

          if (!isCreatorRef.current) {
            await set(
              ref(db, "rooms/" + roomID + "/status/startedFinalAt"),
              serverTimestamp()
            );
          }
        };

        helperFunction1();
      }
    });

    const startFinalAtRef = ref(
      db,
      "rooms/" + roomID + "/status/startedFinalAt"
    );
    const unsubscribeStartAtListener = onValue(startFinalAtRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setStartedFinalAt(data);
      }
    });

    const correctAnswerRef = ref(
      db,
      "rooms/" + roomID + "/questions/finalCorrectAnswer"
    ); // Pfad zum ausgewählten Feld in der Realtime Database
    const unsubscribeCorrectAnswerListener = onValue(
      correctAnswerRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setCorrectAnswer(data); // Aktualisiere den Wert im State mit dem Wert aus der Datenbank
        }
      }
    );

    const AnswerInDBRef1 = ref(
      db,
      "rooms/" + roomID + "/questions/finalPlayerAnswer1"
    );
    const unsubscribeFinalAnswer1InDBListener = onValue(
      AnswerInDBRef1,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setFinalist1Answer(data.Answer);
        }
      }
    );

    const AnswerInDBRef2 = ref(
      db,
      "rooms/" + roomID + "/questions/finalPlayerAnswer2"
    );
    const unsubscribeFinalAnswer2InDBListener = onValue(
      AnswerInDBRef2,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setFinalist2Answer(data.Answer);
        }
      }
    );

    const playersRef = ref(db, "rooms/" + roomID + "/players");
    const unsubscribePlayersListener = onValue(playersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const playersArray = Object.entries(data).map(
          ([playerID, playerData]) => {
            const votedBy = playerData.votedBy
              ? Object.keys(playerData.votedBy)
              : [];
            return { playerID: playerID, ...playerData, votedBy: votedBy };
          }
        );
        setPlayers(playersArray);
        if (getPlayerById(uid, playersArray).isCreator) {
          setIsCreator(false);
        }

        // man muss sortieren da sonst manchmal bugg wenn gleicher name
        //ne sortieren kann man eigentlich lassen
        const alivePlayers = getAlivePlayers(playersArray);
        const sortedPlayers = alivePlayers.sort((a, b) =>
          a.playerID.localeCompare(b.playerID)
        );
        if (!creatorExists(sortedPlayers)) {
          set(
            ref(
              db,
              "rooms/" +
                roomID +
                "/players/" +
                sortedPlayers[0].playerID +
                "/isCreator"
            ),
            true
          );
        }
      }
    });

    if (!isCreatorRef.current) {
      // starte final
      set(ref(db, "rooms/" + roomID + "/status/startFinal"), startFinal);
    }

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      finalTimerSound.stop();
      unsubscribeFinalAnswer1InDBListener();
      unsubscribeStartAtListener();
      unsubscribeTimeOffListener();
      unsubscribeQuestionListener();
      unsubscribeCorrectAnswerListener();
    };
  }, []);

  useEffect(() => {
    const gameLoop1 = async () => {
      // sucht fragen raus
      if (!isCreatorRef.current) {
        const tmpRandomQuestion = getRandomEstimationQuestion(language);

        await set(
          ref(db, "rooms/" + roomID + "/questions/finalQuestion"),
          tmpRandomQuestion.question
        );
        await set(
          ref(db, "rooms/" + roomID + "/questions/finalCorrectAnswer"),
          tmpRandomQuestion.answer
        );
      }
    };

    if (startFinal >= 1) {
      gameLoop1();
    }
  }, [startFinal]);

  useEffect(() => {
    const gameLoop2 = async () => {
      if (finalist1.playerID === uid) {
        setMyTurn1(true);
      }
      if (finalist2.playerID === uid) {
        setMyTurn2(true);
      }
      finalTimerSound.play();
      await timer(
        estimationQuestionTime,
        setCount,
        startedFinalAt,
        serverTimeOffset
      );

      if (finalist1.playerID === uid) {
        sendAnswer1();
      }
      if (finalist2.playerID === uid) {
        sendAnswer2();
      }
    };
    // serverTimestamp() gibt 2 triggers vom listener mit der lokalen und mit der server Zeit deswegen braucht man das
    if (startedFinalAt !== null) {
      if (!isCreatorRef.current) {
        if (tmp === 2) {
          gameLoop2();
          setTmp((currentTmp) => {
            return currentTmp + -1;
          });
        } else {
          setTmp((currentTmp) => {
            return currentTmp + 1;
          });
        }
      } else {
        gameLoop2();
      }
    }
  }, [startedFinalAt]);

  useEffect(() => {
    if (myTurn1) {
      inputRef1.current.focus();
    }
  }, [myTurn1]);

  useEffect(() => {
    if (myTurn2) {
      inputRef2.current.focus();
    }
  }, [myTurn2]);

  const sendAnswer1 = async () => {
    setMyTurn1(false);
    let tmpAnswer;
    if (inputRef1.current) {
      tmpAnswer = inputRef1.current.value;
    } else {
      tmpAnswer = "";
    }

    // erhöhe AnswerCounter
    // mache das da beim onDisconnect dummy auf negativen wert gsetzt wird,
    // negative werte sind shclecht weil es zu porblemen mit anderen onDisconencts kommen kann
    //diese sind ebenfalls 0
    dummyCounterRef.current = Math.max(0, dummyCounterRef.current);
    dummyCounterRef.current += 1;

    const currAnswer = {
      Answer: tmpAnswer,
      dummy: dummyCounterRef.current,
    };

    const currEmptyAnswer = {
      Answer: "no answer",
      dummy: dummyCounterRef.current,
    };
    if (tmpAnswer === null || tmpAnswer === "") {
      await set(
        ref(db, "rooms/" + roomID + "/questions/finalPlayerAnswer1"),
        currEmptyAnswer
      );
    } else {
      await set(
        ref(db, "rooms/" + roomID + "/questions/finalPlayerAnswer1"),
        currAnswer
      );
    }
    //delete answer from input field
    setPlayerAnswerInput1("");
  };

  const sendAnswer2 = async () => {
    setMyTurn2(false);
    let tmpAnswer;
    if (inputRef2.current) {
      tmpAnswer = inputRef2.current.value;
    } else {
      tmpAnswer = "";
    }

    // erhöhe AnswerCounter
    // mache das da beim onDisconnect dummy auf negativen wert gsetzt wird,
    // negative werte sind shclecht weil es zu porblemen mit anderen onDisconencts kommen kann
    //diese sind ebenfalls 0
    dummyCounterRef.current = Math.max(0, dummyCounterRef.current);
    dummyCounterRef.current += 1;

    const currAnswer = {
      Answer: tmpAnswer,
      dummy: dummyCounterRef.current,
    };

    const currEmptyAnswer = {
      Answer: "no answer",
      dummy: dummyCounterRef.current,
    };
    if (tmpAnswer === null || tmpAnswer === "") {
      await set(
        ref(db, "rooms/" + roomID + "/questions/finalPlayerAnswer2"),
        currEmptyAnswer
      );
    } else {
      await set(
        ref(db, "rooms/" + roomID + "/questions/finalPlayerAnswer2"),
        currAnswer
      );
    }
    //delete answer from input field
    setPlayerAnswerInput2("");
  };

  useEffect(() => {
    if (finalist1Answer !== null && finalist2Answer !== null) {
      const finishRound = async () => {
        finalTimerSound.stop();
        setCount(0);

        // unterbricht den timer falls sendAnswer augerufen wurde ebvor die ZEit vorbei ist
        // der timer wird zwar beendet aber das promise wird nicht aufgelöst, wodurch der code nach dem timer
        // nie ausgeführt wird
        clearInterval(intervalID);


        setShowBothPlayerAnswers(true);

        await wait(Math.max(finalist1AnswerRef.current.length, finalist2AnswerRef.current.length)*200 + 4000);

        setShowCorrectAnswer(true);

        // Überprüfung und Umwandlung der Antworten
        const isFinalist1Valid = finalist1Answer !== "no answer";
        const isFinalist2Valid = finalist2Answer !== "no answer";

        const finalist1AnswerInt = isFinalist1Valid
          ? parseInt(finalist1Answer, 10)
          : 0;
        const finalist2AnswerInt = isFinalist2Valid
          ? parseInt(finalist2Answer, 10)
          : 0;
        const correctAnswerInt = parseInt(correctAnswer, 10);

        const finalist1Diff = Math.abs(finalist1AnswerInt - correctAnswerInt);
        const finalist2Diff = Math.abs(finalist2AnswerInt - correctAnswerInt);

        if (!isFinalist1Valid && !isFinalist2Valid) {
          setWinner(finalist1);
        } else if (isFinalist1Valid && !isFinalist2Valid) {
          setWinner(finalist1);
        } else if (!isFinalist1Valid && isFinalist2Valid) {
          setWinner(finalist2);
        } else if (finalist1Diff < finalist2Diff) {
          setWinner(finalist1);
        } else if (finalist1Diff > finalist2Diff) {
          setWinner(finalist2);
        } else {
          setWinner(finalist1);
        }

        await wait(correctAnswerRef.current.length*200 + 7000);
        setShowWinnerScreen(true);

        if (!isCreatorRef.current) {
          //await set(ref(db, "rooms/" + roomID + "/status/startGame"), startGame);
        }
      };

      finishRound();
    }
  }, [finalist1Answer, finalist2Answer]);

  const handleKeyDown1 = (event) => {
    if (event.key === "Enter" && myTurn1) {
      sendAnswer1();
    }
  };

  const handleKeyDown2 = (event) => {
    if (event.key === "Enter" && myTurn2) {
      sendAnswer2();
    }
  };

  return (
    <div className="Final-container">
      <TimerAnimation
        count={count}
        questionTime={estimationQuestionTime}
        className={"Final-timer"}
      />
      <RandomQuestion className="Final-question" question={randomQuestion} writingSpeed={50}
      />

      <div className="Final-correct-answer-div">
        {showCorrectAnswer && (
          <>
            <RandomQuestion
              className="Final-correct-answer"
              question={correctAnswer}
              writingSpeed={400}
            />
          </>
        )}
      </div>
      <img className="Final-fire" src={finalFire} />

      <div className="Final-player1-and-input1">
        <div className="Finalist-Player"
        
        style={{
          outline: finalist1.playerID === uid ? "0.6vh solid #28B274" : "none",
        }}
        >
          {finalist1.isCreator && (
            <img className="Final-crown" src={creatorCrown} />
          )}
          <div className="Final-player-avatar-container">
            <img className="Final-avatar-background" src={avatarBackground2} />
            <img className="Final-avatar" src={avatar1} alt="Avatar" />
          </div>
          <h3 className="Final-player-name">{finalist1.playerName}</h3>
        </div>
        <div>
          <div className="Final-input-div">
            {myTurn1 && finalist1.playerID === uid && (
              <input
                className="Final-input"
                onKeyDown={handleKeyDown1}
                maxLength="9"
                type="text"
                id="meinInputFeld"
                value={playerAnswerInput1}
                placeholder="write!"
                ref={inputRef1}
                onChange={(e) => {
                  const value = e.target.value;
                  // Nur Zahlen erlauben
                  if (/^\d*$/.test(value)) {
                    setPlayerAnswerInput1(value);
                  }
                }}
              />
            )}
            {showBothPlayerAnswers && (
              <LastAnswer
                className="Final-player-answer"
                text={finalist1Answer}
                typingSound={typingSound}
                writingSpeed={400}
              />
            )}
          </div>
        </div>
      </div>
      <div className="Final-player2-and-input2">
        <div className="Finalist-Player"
        
        style={{
          outline: finalist2.playerID === uid ? "0.6vh solid #28B274" : "none",
        }}
        >
          {finalist2.isCreator && (
            <img className="Final-crown" src={creatorCrown} />
          )}
          <div className="Final-player-avatar-container">
            <img className="Final-avatar-background" src={avatarBackground2} />
            <img className="Final-avatar" src={avatar2} alt="Avatar" />
          </div>
          <h3 className="Final-player-name">{finalist2.playerName}</h3>
        </div>
        <div>
          <div className="Final-input-div">
            {myTurn2 && finalist2.playerID === uid && (
              <input
                className="Final-input"
                onKeyDown={handleKeyDown2}
                maxLength="9"
                type="text"
                id="meinInputFeld"
                value={playerAnswerInput2}
                placeholder="write!"
                ref={inputRef2}
                onChange={(e) => {
                  const value = e.target.value;
                  // Nur Zahlen erlauben
                  if (/^\d*$/.test(value)) {
                    setPlayerAnswerInput2(value);
                  }
                }}
              />
            )}
            {showBothPlayerAnswers && (
              <LastAnswer
                className="Final-player-answer"
                text={finalist2Answer}
                typingSound={typingSound}
                writingSpeed={400}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
