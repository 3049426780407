export const Credits = () => {
  return (
    <div>
      <p>
        This game is heavily inspired by the YouTube show 'Der Dümmste Fliegt'
        by the YouTuber{" "}
        <a
          href="https://www.youtube.com/@Reved"
          target="_blank"
          rel="noopener noreferrer"
        >
          Reved
        </a>
        , who in turn was strongly influenced by the YouTuber{" "}
        <a
          href="https://www.youtube.com/@pietsmiet"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pitesmiet
        </a>
        .
      </p>
    </div>
  );
};
