import React from 'react';

export const VotingPlayerAvatarTooltip = ({ votingData, playerID }) => {
  const playerData = votingData[playerID];
  if (!playerData) {
    return null;
  }
  
  let formattedOutput = "";

  const questionKeys = Object.keys(playerData);
  questionKeys.forEach((questionKey, index) => {
    const questionData = playerData[questionKey];
    const questionNumber = questionKey.replace(/\D/g, '');
    // Ersetzen Sie #00FF00 durch Ihren gewünschten Hexcode
    formattedOutput += `${questionNumber}. ${questionData.Question}\n <span  style="color: #13f88d;">${questionData.Answer}</span>`;
    if (index < questionKeys.length - 1) {
      formattedOutput += "\n\n";
    }
  });

  return (
    <div className="Voting-player-avatar-tooltip">
      {formattedOutput.split('\n').map((line, index) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: line === "" ? "<br />" : line }} />
      ))}
    </div>
  );
};
