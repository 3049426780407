export const Contact = () => {
  return (
    <div>
      <p>
        I welcome any suggestions for new questions, changes to the game, bug
        reports, or any other feedback that could help improve the game. Please
        send these to improve@kickthefool.com.
      </p>

      <p>For all other inquiries, please use contact@kickthefool.com.</p>
    </div>
  );
};
