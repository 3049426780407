import { signInAnonymously, updateProfile } from "firebase/auth";
import "../styles/Auth.css";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useRef, useEffect, useContext } from "react";
import { LastAnswer } from "./sections/LastAnswer";
import { avataaars, lorelei, dylan, bigSmile } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";
import { useMemo } from "react";
import { db, auth, uid } from "../firebase-config.js";
import { TimerAnimation } from "./sections/TimerAnimation";
import { FaThumbsDown } from "react-icons/fa";
import avatarBackground2 from "../styles/images/avatarBackground2.svg";
import brain from "../styles/images/brain.svg";
import thumbsDown from "../styles/images/thumbsDown.svg";
import creatorCrown from "../styles/images/creatorCrown.svg";
import {
  resetAllVotes,
  getAlivePlayers,
  waitForever,
  wait,
  avatars,
  getPlayerIndexById,
  getTopVotedPlayers,
  getPlayerById,
  totalVoteNumber,
  creatorExists,
} from "../utilities/helperFunctions.js";
import { FaHandPointLeft } from "react-icons/fa"; // Importing the hand pointing left icon from FontAwesome
import { FaRegHandPointLeft } from "react-icons/fa6";
import { VotingPlayerAvatarTooltip } from "./sections/VotingPlayerAvatarTooltip";
import { VolumeSlider } from "./sections/VolumeSlider";

import {
  onValue,
  getDatabase,
  onChildChanged,
  ref,
  set,
  update,
  push,
  hasChild,
  exists,
  get,
  serverTimestamp,
  off,
  remove,
  onDisconnect,
} from "firebase/database";

import "../styles/Voting.css";

export const Voting = ({
  setIsCreator,
  volume,
  setVolume,
  setWinner,
  setShowWinnerScreen,
  setVotingNumber,
  setShowVoting,
  showVoting,
  votingTime,
  players,
  votingNumber,
  roomID,
  setPlayers,
  isCreator,

  setShouldJoin,
  setInLobby,
  setShowFinalScreen,
  setFinalists,
  clockSound,

  listener1Ref,
  listener2Ref,
  listener3Ref,
  listener4Ref,
  listener5Ref,
  booSound

}) => {
  const [votingData, setVotingData] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);
  const isCreatorRef = useRef(isCreator);

  const [intervalID, setIntervalID] = useState(0);
  const [count, setCount] = useState(votingTime);
  const [votingPhase, setVotingPhase] = useState("Voting Ends In: ");
  const [votingProcessFinished, setVotingProcessFinished] = useState(false);
  const [tmp1, setTmp1] = useState(1);
  const [tmp2, setTmp2] = useState(1);
  const playersRef = useRef(players);
  const playersRef2 = useRef(players);

  const [backToGame, setBackToGame] = useState(0);
  const [startedVotingAt, setStartedVotingAt] = useState(null);
  const [startedVotingOutroAt, setStartedVotingOutroAt] = useState(null);

  // Flag, um den ersten Call zu überspringen
  // das isInitialLoad zeug braucht man da onValue nicht nur bei änderung sondern auch beim initialisieren des listeners ausgeführt wird.
  //beim ersten rendern des Components (VotingNumber= 1) ist allerdings noch nichts in der datenbank deswegne wird auch onValue nicht beim initalsieren getrigered sondern nur bei der erstend änderung/hinzufügung
  const isInitialLoad1 = useRef(votingNumber <= 1 ? false : true);
  const isInitialLoad2 = useRef(votingNumber <= 1 ? false : true);
  const isInitialLoad3 = useRef(votingNumber <= 1 ? false : true);
  const [votes, setVotes] = useState({});
  const prevVotesRef = useRef(votes);
  const prevPlayerRef = useRef(null);

  const [currentUid, setCurrentUid] = useState(uid);


    const renderBrains = (numLives) => {
    return (
      <div className="Voting-lives">
        {Array.from({ length: numLives }, (_, i) => (
          <img
            key={i}
            className="Voting-live"
            src={brain}
            alt={`Brain ${i + 1}`}
          />
        ))}
      </div>
    );
  };

  useEffect(() => {
    isCreatorRef.current = isCreator;
  }, [isCreator]);


  useEffect(() => {
    playersRef.current = players;
  }, [players]);

  useEffect(() => {
    if (currentUid) {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
      const newPlayerRef = ref(
        db,
        "rooms/" + roomID + "/players/" + currentUid
      );
      onDisconnect(newPlayerRef).update({
        lives: 0,
        isCreator: false,
        online: false,
      });
      prevPlayerRef.current = newPlayerRef;
    }

    return () => {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
    };
  }, [currentUid]);

  let votingTimeValue = votingTime;

  let serverTimeOffset = 0;

  useEffect(() => {}, [votingNumber]);

  const avatars = useMemo(() => {
    return players.map((player) =>
      createAvatar(bigSmile, {
        size: 70,
        seed: player.playerName,
        backgroundColor: []
      }).toDataUri()
    );
  }, [players]);


  useEffect(() => {
    if (count == 0) {
      clockSound.stop();
  
    }
  }, [count]);

  const timer = (time, setCount, startAt, serverTimeOffset) => {
    let soundPlayed = false; 
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        setIntervalID(interval);
        //console.log("intervalid1: " + intervalID);
        const timeLeft =
          time * 1000 - (Date.now() - startAt - serverTimeOffset);
        if (timeLeft <= 0) {
          clockSound.stop();
          clearInterval(interval);
          setCount(0.0);
          resolve(); // Resolve the Promise when the timer completes
        } else {

          //setCount(parseFloat(`${Math.floor(timeLeft/1000)}.${timeLeft % 1000}`));
          //setCount(Math.floor(timeLeft / 1000)); // Zeile geändert
          const secondsLeft = timeLeft / 1000;
          if( secondsLeft <= 4 && !soundPlayed){
            clockSound.play();
            soundPlayed = true; 
          }
          setCount(secondsLeft.toFixed(2)); // Setze die Zeit mit zwei Nachkommastelle
        }
      }, 100);
    });
  };

  const handlePlayerLifeDecrease = () => {
    set(ref(db, "rooms/" + roomID + "/status/startAt"), serverTimestamp());
  };

  useEffect(() => {
    clockSound.stop();

    if(listener1Ref.current){
      window.removeEventListener('popstate', listener2Ref.current);

    }
    if(listener2Ref.current){
      window.removeEventListener('popstate', listener3Ref.current);

    }
    if(listener4Ref.current){
      window.removeEventListener('popstate', listener4Ref.current);

    }
    if(listener5Ref.current){
      window.removeEventListener('popstate', listener5Ref.current);

    }
    // onDisconnects werden nur beim zurück navigieren getrigered wenn man von der Seite runter navigiert
    // um auch handere zurück pfeile zu handeln dieses zeug
    // popstate wird allerdings auch bei vorwärtspfeil getrigered was mögliche buggs verursacht
    // man braucht listener1Ref um in einem anderen Component diesen listener canceln zu können
    // man kann nicht in diesem komponent canceln da sonst beim zurück pfeil gecancelt wird bevor handePopstate ausgeführt wird
    function handlePopState3() {
      const newPlayerRef = ref(
        db,
        "rooms/" + roomID + "/players/" + currentUid
      );
      update(newPlayerRef, {
        lives: 0,
        isCreator: false,
        online: false,
      });

      setShouldJoin(true);
      setInLobby(true);
    }

    listener3Ref.current = handlePopState3;

    // Füge den Event-Listener hinzu
    window.addEventListener("popstate", handlePopState3);


    
    const timeOffRef = ref(db, ".info/serverTimeOffset");
    const timeOffListener = onValue(timeOffRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        serverTimeOffset = snapshot.val();
      }
    });

    const startedVotingAtRef = ref(
      db,
      "rooms/" + roomID + "/status/startedVotingAt"
    );
    const unsubscribeStartedVotingAListener = onValue(
      startedVotingAtRef,
      (snapshot) => {
        //hier brauch man  isInitialLoad eigentlich auch
        // aber der callback vom initalisieren und ändern von startedVotingAt in der db ist sehr shcnell hint einander. Da die callbacks von den listenern asynchron sind ist das alles vebruggt weil es parallel ausgeführt wird und funktioniert nicht mit isInitialLoad.
        //deswegen andere Lösung: remove startetVotingAt am ende jedes mal.
        // Dadurch wird beim initialisieren nicht in der db kein startedAt gefunden und der listener wird nur einmal getrigered (trigger vom initialiseren wird verhindert).
        // Es ist so als würde der component jedes mal zum ersten mal gerendert werden.
        const data = snapshot.val();
        if (data) {
          setStartedVotingAt(data);
        }
      }
    );

    const backToGameRef = ref(db, "rooms/" + roomID + "/status/backToGame");
    const unsubscribeBackToGameListener = onValue(backToGameRef, (snapshot) => {
      // das isInitialLoad zeug braucht man da onValue nicht nur bei änderung sondern auch beim initialisieren des listeners ausgeführt wird.
      // ohne das ist ein bugg das voting direkt übersprungen wird, dieser bugg tritt immer außer beim ersten voting auf da beim ertsen voting backToGame noch nciht in der Datenbank ist
      if (isInitialLoad1.current) {
        isInitialLoad1.current = false; // Flag zurücksetzen
        return; // Skip the first call
      }

      const data = snapshot.val();
      setBackToGame(data);
    });

    const startedVotingOutroAtRef = ref(
      db,
      "rooms/" + roomID + "/status/startedVotingOutroAt"
    ); // Pfad zum ausgewählten Feld in der Realtime Database
    const unsubscribeStartedVotingOutroListener = onValue(
      startedVotingOutroAtRef,
      (snapshot) => {
        /// das isInitialLoad zeug braucht man da onValue nicht nur bei änderung sondern auch beim initialisieren des listeners ausgeführt wird.
        // ohne das ist ein bugg das voting direkt übersprungen wird, dieser bugg tritt immer außer beim ersten voting auf da beim ertsen voting backToGame noch nciht in der Datenbank ist
        if (isInitialLoad3.current) {
          isInitialLoad3.current = false; // Flag zurücksetzen
          return; // Skip the first call
        }
        const data = snapshot.val();
        if (data) {
          setStartedVotingOutroAt(data);
        }
      }
    );

    async function fetchVotingData() {
      try {
        const votingRef = ref(
          db,
          "rooms/" + roomID + "/history/Voting" + votingNumber
        );
        const snapshot = await get(votingRef);

        if (snapshot.exists()) {
          const votingData = snapshot.val();
          setVotingData(votingData);
        } else {
          console.log("Keine Daten für das angegebene Voting gefunden.");
        }
      } catch (error) {
        console.error("Fehler beim Lesen des Votings:", error);
      }
    }
    fetchVotingData();

    const playersRef = ref(db, "rooms/" + roomID + "/players");
    const unsubscribePlayersListener = onValue(playersRef, (snapshot) => {
      // hier ist kein Problem wenn es ohne veränderung in db trotzdem abgerufen wird
      // möglichkeit für zukunft  um firebase calls zu minimieren
      const data = snapshot.val();
      if (data) {
        const playersArray = Object.entries(data).map(
          ([playerID, playerData]) => {
            const votedBy = playerData.votedBy
              ? Object.keys(playerData.votedBy)
              : [];
            return { playerID: playerID, ...playerData, votedBy: votedBy };
          }
        );
        setPlayers(playersArray);


        // Erstellen eines Votes-Objekts, das nur die votedBy-Informationen enthält
        const votesMap = playersArray.reduce((map, player) => {
          map[player.playerID] = player.votedBy;
          return map;
        }, {});
        setVotes(votesMap);

        // detailed comments about this in lobby component at palyers listener
        if (getPlayerById(uid, playersArray).isCreator) {
          setIsCreator(false);
        } else {
          setIsCreator(true);
        }

        if (!creatorExists(playersArray)) {
          const nextAlivePlayerId = getAlivePlayers(playersArray)[0].playerID;

          console.log("sete eine creator auf true: " + nextAlivePlayerId);
          set(
            ref(
              db,
              "rooms/" + roomID + "/players/" + nextAlivePlayerId + "/isCreator"
            ),
            true
          );
        }
        // wenn ein spielr ein leben verloren hat mache einen boo sound
        playersArray.forEach((player) => {
          const previousPlayerLives = playersRef2.current[getPlayerIndexById(player.playerID, playersArray)].lives;
          if (previousPlayerLives) {
            const currentPlayerLives = player.lives; 

            if (previousPlayerLives > currentPlayerLives) {
              booSound.play();
            }
          }
        });
        playersRef2.current = playersArray;



        //if only 2 player remain go instant to WinnerScreen
        const alivePlayers = getAlivePlayers(playersArray);
       // console.log("alivePlayers: " + JSON.stringify(alivePlayers));
       const tmp = async () => {
          if (alivePlayers.length <= 2) {
            await wait(4000);
            if (!isCreatorRef.current) {
              set(ref(db, "rooms/" + roomID + "/status/backToGame"), votingNumber);
            }
          }
        }
        tmp();

       }
    });

    if (!isCreatorRef.current) {
      set(
        ref(db, "rooms/" + roomID + "/status/startedVotingAt"),
        serverTimestamp()
      );
      set(ref(db, "rooms/" + roomID + "/lastActivity"), serverTimestamp());

    }

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      clockSound.stop();
      unsubscribePlayersListener();
      unsubscribeStartedVotingAListener();
      unsubscribeStartedVotingOutroListener();
      unsubscribeBackToGameListener();
    };
  }, []);



  useEffect(() => {
    if (JSON.stringify(prevVotesRef.current) !== JSON.stringify(votes)) {
      const alivePlayers = getAlivePlayers(playersRef.current);

      if (totalVoteNumber(players) === alivePlayers.length) {
        evaluateVoting();
      }
    }
    prevVotesRef.current = votes;
  }, [votes]);

  const evaluateVoting = async () => {
    setCount(0.0);
    clearInterval(intervalID);
    await wait(2500);

    if (!isCreatorRef.current) {
      //nutze playersRef statt einfach players da sonst komischer Bugg. Komischer Bugg ensteht da funktion gerendert wird mit altem players und es dann trotzdem noch altes players ntzt obwohl es schon neues gibt. der bugg ist NICHT zu späte players aktuelliserung

      const alivePlayers = getAlivePlayers(playersRef.current);
      const topVotedPlayers = getTopVotedPlayers(alivePlayers);

      if (topVotedPlayers.length === 1) {
        await set(
          ref(
            db,
            `rooms/${roomID}/players/${topVotedPlayers[0].playerID}/lives`
          ),
          topVotedPlayers[0].lives - 1
        );
      } else if (topVotedPlayers.length > 1) {
        const randomIndex = Math.floor(Math.random() * topVotedPlayers.length);
        await set(
          ref(
            db,
            `rooms/${roomID}/players/${topVotedPlayers[randomIndex].playerID}/lives`
          ),
          topVotedPlayers[randomIndex].lives - 1
        );
      } else {
        //console.log("No players found.");
      }

      set(
        ref(db, "rooms/" + roomID + "/status/startedVotingOutroAt"),
        serverTimestamp()
      );
    }
  };

  useEffect(() => {
    const votingProcess = async () => {
      await timer(votingTime, setCount, startedVotingAt, serverTimeOffset);
      // const updatedVotingTime = parseInt(votingTime) + 6;

      setHasVoted(true);

      //evaluate voting
      evaluateVoting();
    };

    if (startedVotingAt) {
      if (!isCreatorRef.current) {
        if (tmp1 === 2) {
          votingProcess();
          setTmp1((currentTmp) => {
            return currentTmp + -1;
          });
        } else {
          setTmp1((currentTmp) => {
            return currentTmp + 1;
          });
        }
      } else {
        votingProcess();
      }
    }
  }, [startedVotingAt]);

  useEffect(() => {
    const endVoting = async () => {
      setVotingNumber((currentTmp) => {
        return currentTmp + 1;
      });
      setVotingPhase("Proceeding In: ");
      await wait(4000);
      if (!isCreatorRef.current) {
        set(ref(db, "rooms/" + roomID + "/status/backToGame"), votingNumber);
      }
    };

    if (startedVotingOutroAt) {
      if (!isCreatorRef.current) {
        if (tmp2 === 2) {
          endVoting();
          setTmp2((currentTmp) => {
            return currentTmp + -1;
          });
        } else {
          setTmp2((currentTmp) => {
            return currentTmp + 1;
          });
        }
      } else {
        endVoting();
      }
    }
  }, [startedVotingOutroAt]);

  useEffect(() => {
    if (backToGame) {
      if (!isCreatorRef.current) {
        resetAllVotes(players, roomID);
        //wichtige zeile, siehe Listener von startedVotingAt
        remove(ref(db, "rooms/" + roomID + "/status/startedVotingAt"));
      }
      const alivePlayers = getAlivePlayers(playersRef.current);
      setFinalists(alivePlayers);

      setWinner(alivePlayers[0]);
      if (alivePlayers.length == 2) {
        setShowFinalScreen(true);
      }
      else if (alivePlayers.length <= 1) {
        setShowWinnerScreen(true);
      } else {
        setShowVoting(false);
      }
    }
  }, [backToGame]);

  const voteForPlayer = async (playerID) => {
    setHasVoted(true);
    // add vote to database
    await set(
      ref(db, `rooms/${roomID}/players/${playerID}/votedBy/${uid}`),
      true
    );
    // here
  };


  return (
    <div className="Voting-container">
      <h1 className="Voting-headline">TIME TO VOTE</h1>
            <TimerAnimation count={count} questionTime={votingTime} className={"Voting-timer"} />

      <div className="Voting-player-list">
        {players.map((player, index) => (
          <button
            className={`Voting-player ${player.lives <= 0 ? "grayed-out" : ""}` }
            disabled={hasVoted || getPlayerById(uid, players).lives <= 0}
            onClick={() => voteForPlayer(player.playerID)}
            style={{
              outline:
                player.playerID === uid ? "0.6vh solid #28B274" : "none",
          
            }}
          >
                      {player.isCreator && (
                <img className="Voting-crown" src={creatorCrown} />
              )}
            <div className="Voting-player-avatar-container">
              <img
                className="Voting-avatar-background"
                src={avatarBackground2}
              />
              <img
                className="Voting-avatar"
                src={avatars[index]}
                alt="Avatar"
              />
              {votingData && (
                <VotingPlayerAvatarTooltip
                  votingData={votingData}
                  playerID={player.playerID}
                />
              )}
            </div>
            <h3 className="Voting-player-name">{player.playerName}</h3>

            {renderBrains(player.lives)}
            <div>
              {player.votedBy.length > 0 && (
                <div className="Voting-by-div">
                 {player.votedBy.map((voterID) => (
                    <img
                      className="Voting-voted-by-avatar"
                      src={avatars[getPlayerIndexById(voterID, players)]}
                      alt="Avatar"

                    />
                ))}

                </div>
              )}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
